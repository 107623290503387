import React from "react";
import { home } from "./strings";
import { Box, LinkButton, Flex, Heading, Text } from "./ui";
import { useLocation } from "react-router-dom";

export default function Home() {
  const location = useLocation();

  const query = location != null ? location.search : "";

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading variant="heading1">{home.title}</Heading>
      <Flex mb={2}>
        <Text>{home.body}</Text>
      </Flex>
      <Flex mr={2}>
        <Box>
          <LinkButton to={`/applicant/applications/new${query}`} variant="primary">
            {home.buttonText}
          </LinkButton>
        </Box>
      </Flex>
    </Flex>
  );
}
