import React from "react";
import omit from "lodash/omit";

export enum Action {
  NEW = "NEW",
  PENDING = "PENDING",
  UPLOADING = "UPLOADING",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
  REMOVE = "REMOVE",
  SET_NEW_TO_PENDING = "SET_NEW_TO_PENDING",
  SET_PENDING_TO_NEW = "SET_PENDING_TO_NEW",
  RESET = "RESET",
}

export enum FileStatus {
  NEW = "NEW",
  PENDING = "PENDING",
  UPLOADING = "UPLOADING",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
}

export type IState = {
  [fname: string]: { status: FileStatus; file: File; token?: string };
};

export type FilesByStatus = { [key in FileStatus]: File[] };

export const groupByStatus = (state: IState): FilesByStatus =>
  Object.keys(state).reduce<FilesByStatus>(
    (a, k) => ({
      ...a,
      [state[k].status]: a[state[k].status] ? [...a[state[k].status], state[k].file] : [state[k].file],
    }),
    {
      NEW: [],
      UPLOADING: [],
      PENDING: [],
      COMPLETE: [],
      ERROR: [],
    },
  );

const setStatusesWhere = (state: IState, status: FileStatus, newStatus: FileStatus): IState => {
  const toChange = Object.values(state).filter(s => s.status === status);
  if (toChange.length === 0) {
    return state;
  }
  return toChange.reduce(
    (a, s) => ({
      ...a,
      [s.file.name]: { ...s, status: newStatus },
    }),
    state,
  );
};

const setStatuses = (status: FileStatus, files: File[]): IState =>
  Array.from(files).reduce<IState>((a, file) => ({ ...a, [file.name]: { status, file } }), {});

export const isValid = (state: IState): boolean => {
  return Object.values(state).every(({ status }) => status === FileStatus.COMPLETE);
};

export type IAction =
  | { type: Action.NEW; files: File[] }
  | { type: Action.PENDING; files: File[] }
  | { type: Action.UPLOADING; file: File }
  | { type: Action.COMPLETE; file: File; token: string }
  | { type: Action.ERROR; file: File }
  | { type: Action.REMOVE; file: File }
  | { type: Action.SET_NEW_TO_PENDING }
  | { type: Action.SET_PENDING_TO_NEW }
  | { type: Action.RESET };

export const initialState: IState = {};

// Uploading, queued and failed files states are internal, completed files
export const reducer: React.Reducer<IState, IAction> = (state = initialState, action) => {
  switch (action.type) {
    case Action.NEW:
      return { ...state, ...setStatuses(FileStatus.NEW, action.files) };

    case Action.PENDING:
      return { ...state, ...setStatuses(FileStatus.PENDING, action.files) };

    case Action.UPLOADING:
      return {
        ...state,
        [action.file.name]: { file: action.file, status: FileStatus.UPLOADING },
      };
    case Action.COMPLETE: {
      // removes from here and updates onChange
      return {
        ...state,
        [action.file.name]: {
          file: action.file,
          status: FileStatus.COMPLETE,
          token: action.token,
        },
      };
    }
    case Action.ERROR:
      return {
        ...state,
        [action.file.name]: { file: action.file, status: FileStatus.ERROR },
      };
    case Action.REMOVE:
      return omit(state, [action.file.name]);

    case Action.SET_NEW_TO_PENDING:
      // set all NEW to PENDING
      return setStatusesWhere(state, FileStatus.NEW, FileStatus.PENDING);

    case Action.SET_PENDING_TO_NEW:
      // set all PENDING to NEW
      return setStatusesWhere(state, FileStatus.PENDING, FileStatus.NEW);

    case Action.RESET:
      return {};

    default:
      return state;
  }
};

export const hasErrors = (value: IState): boolean => {
  const byStatus = groupByStatus(value);
  return byStatus[Action.ERROR].length > 0;
};
