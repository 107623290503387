import { checkExhausted, Opt } from "../utils";
import { FeeCategory, FeeStatus, FeeStatusEnum, UkImmigrationStatus, UkResidency } from "./enums.generated";

export function fromFeeStatus(feeStatus: Opt<FeeStatus>): FeeCategory | null {
  switch (feeStatus) {
    case "HHC":
      return "Home";
    case "HHP":
      return null;
    case "HCC":
      return "Home";
    case "HCP":
      return null;
    case "HEC":
      return "Home";
    case "HEP":
      return null;
    case "OHP":
      return null;
    case "OHC":
      return "Home";
    case "OEC":
      return "Eu";
    case "OEP":
      return null;
    case "OOC":
      return "Overseas";
    case "OOP":
      return null;
    case "QQP":
      return null;
    case null:
    case undefined:
      return null;
    default:
      return checkExhausted(feeStatus);
  }
}

export function guessFeeCategory(
  feeStatus: Opt<FeeStatus>,
  ukResidency: Opt<UkResidency>,
  ukImmigrationStatus: Opt<UkImmigrationStatus>,
): FeeCategory | null {
  const fromResidencyAndImmigration = (): FeeCategory | null => {
    switch (ukResidency) {
      case "A":
        return ukImmigrationStatus === "D" ? "Overseas" : "Home";
      case "B":
        return ukImmigrationStatus === "C" ? "Home" : "Overseas";
      case "C":
        return "Overseas";
      case null:
      case undefined:
        return null;
      default:
        return checkExhausted(ukResidency);
    }
  };

  return fromFeeStatus(feeStatus) ?? fromResidencyAndImmigration() ?? null;
}

export function isConfirmed(feeStatus: FeeStatus | undefined | null): boolean {
  return !!feeStatus && feeStatus.endsWith("C");
}

export function initialFeeStatus(
  currentFeeStatus: Opt<FeeStatus>,
  ukResidency: Opt<UkResidency>,
  ukImmigrationStatus: Opt<UkImmigrationStatus>,
): FeeStatus {
  if (currentFeeStatus && isConfirmed(currentFeeStatus)) {
    return currentFeeStatus;
  } else {
    return initialFeeStatusFromCategory(guessFeeCategory(null, ukResidency, ukImmigrationStatus));
  }
}

export function initialFeeStatusFromCategory(feeCat: FeeCategory | null): FeeStatus {
  switch (feeCat) {
    case "Home":
      return FeeStatusEnum.HomeProvisional;
    case "Eu":
      return FeeStatusEnum.OverseasEuProvisional;
    case "Overseas":
      return FeeStatusEnum.OverseasProvisional;
    case null:
      return FeeStatusEnum.Query;
    default:
      return checkExhausted(feeCat);
  }
}

export function feeCategoryLabel(feeCat: FeeCategory): string {
  switch (feeCat) {
    case "Home":
      return "home";
    case "Eu":
      return "EU";
    case "Overseas":
      return "overseas";
    default:
      return checkExhausted(feeCat);
  }
}
