import React from "react";
import { Input } from "theme-ui";

export type IValue = number | null | undefined;

type Override<T, U> = Omit<T, keyof U> & U;
type InputProps = React.ComponentProps<typeof Input>;

type LocalProps = {
  value: IValue;
  decimalPlaces?: number;
  onChange: (value: IValue) => void;
};

export type IProps = Override<InputProps, LocalProps>;

export const NullableNumberInput = ({ onChange: parentChange, decimalPlaces, value, ...props }: IProps) => {
  const [internal, setInternal] = React.useState<string | null>(null);
  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    if (val === "" || val === undefined) {
      setInternal(null);
      parentChange(null);
    } else {
      if (decimalPlaces) {
        // We need to account for trailing periods and zero pads for numbers here which requires internal state
        // while typing
        const [w, d] = val.split(".", 2);
        const sliced = d !== undefined ? `${w}.${d.slice(0, decimalPlaces)}` : w;
        const castToNumber = Number(sliced);
        if (castToNumber.toString() === sliced) {
          setInternal(null);
          parentChange(castToNumber);
        } else {
          setInternal(sliced);
        }
        // match up to our current point and save leftover
      } else {
        const castToNumber = Number(val);
        if (!Number.isNaN(castToNumber)) {
          parentChange(castToNumber);
        }
      }
    }
  };

  const displayValue = internal ? internal : value === null || value === undefined ? "" : value;

  return <Input onChange={onChange} value={displayValue} pattern="[0-9\.]*" inputMode="numeric" {...props} />;
};

export default NullableNumberInput;
