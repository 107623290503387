import type { Theme, ThemeUIStyleObject } from "@qmspringboard/shared/dist/ui";

const colors = {
  primary: "#0d3273",
  secondary: "#092657",
  tertiary: "#0c746a",
  darkGrey: "#393f49",
  grey2: "#a9a9a9",
  grey: "#f6f6f6",
  lightGrey: "#f6f6f6",
  warning: "#cea600",
  warningDark: "#524200",
  error: "#bd232c",
  errorDark: "#4c0e12",
  white: "#eee",
};

const disabled = {
  backgroundColor: colors.darkGrey,
  cursor: "not-allowed",
  color: colors.grey,
};

const makeTheme = <T extends Theme>(t: T) => t;

const errorContainer: ThemeUIStyleObject = {
  display: "block",
  borderRadius: 0,
  fontFamily: "body",
  fontSize: 1,
  position: "relative",
  color: colors.white,
  p: 2,
  marginTop: "5px",
  backgroundColor: colors.error,
  ":before": {
    bottom: "100%",
    left: "40px",
    border: "solid transparent",
    content: "''",
    height: "0",
    width: "0",
    position: "absolute",
    pointerEvents: "none",
    borderColor: "rgba(136, 183, 213, 0)",
    borderBottomColor: colors.error,
    borderWidth: "5px",
  },
};

const theme = makeTheme({
  fontSizes: [14, 18, 20, 32, 60],
  colors,
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: "Source Sans Pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Fira Sans, sans-serif",
    heading:
      "Source Sans Pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Fira Sans, sans-serif",
    sans: "Source Sans Pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Fira Sans, sans-serif",
    mono: "Menlo, monospace",
  },
  fontWeights: {
    heading: 700,
  },
  text: {
    heading1: {
      fontSize: 3,
    },
    heading2: {
      fontSize: 2,
    },
    ref: {
      backgroundColor: colors.tertiary,
      color: colors.white,
      display: "inline",
    },
    error: errorContainer,
    warning: {
      ...errorContainer,
      color: colors.white,
      backgroundColor: colors.warning,
      ":before": {
        ...errorContainer[":before"],
        borderBottomColor: colors.warning,
      },
    },
  },
  forms: {
    checkbox: {
      borderRadius: 0,
      "input:disabled ~ &": {
        fill: colors.grey2,
        opacity: 0.3,
        cursor: "not-allowed",
      },
    },
    select: {
      borderColor: colors.primary,
      boxShadow: "inset 0 0 0 2px",
      borderWidth: 0,
      borderRadius: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      fill: colors.primary,
      ":disabled": {
        cursor: "not-allowed",
        borderColor: colors.grey,
        color: colors.grey2,
        fill: colors.grey2,
      },
    },
    input: {
      borderColor: colors.primary,
      borderRadius: 0,
      borderWidth: 0,
      flex: 1,
      boxShadow: "inset 0 0 0 2px",
      p: 2,
    },
  },
  layout: {
    page: {
      py: 2,
      px: [2, 2, 0, 0],
      maxWidth: "48.4444rem",
      margin: "0 auto",
    },
    overlay: {
      display: "flex",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(255,255,255,0.9)",
    },
    dropzone: {
      border: `2px dashed ${colors.darkGrey}`,
      cursor: "pointer",
      alignItems: "center",
      px: 3,
      bg: "grey",
    },
  },
  messages: {
    error: {
      background: colors.error,
      borderColor: colors.errorDark,
      color: colors.white,
      a: {
        color: colors.white,
      },
    },
    warning: {
      background: colors.warning,
      borderColor: colors.warningDark,
      color: colors.white,
      a: {
        color: colors.white,
      },
    },
  },
  buttonItems: {
    primary: {
      p: 2,
      borderRadius: 0,
      color: colors.white,
      background: colors.tertiary,
      borderTopWidth: 1,
      borderTopColor: colors.white,
      ":disabled": disabled,
      ":hover": {
        cursor: "pointer",
        background: colors.white,
        color: colors.tertiary,
      },
    },
  },
  buttons: {
    primary: {
      borderRadius: 0,
      color: colors.white,
      background: colors.tertiary,
      ":disabled": disabled,
    },
    outlinePrimary: {
      borderRadius: 0,
      color: colors.tertiary,
      backgroundColor: "transparent",
      boxShadow: "inset 0 0 0 2px",
      ":disabled": disabled,
    },
    outlineSecondary: {
      borderRadius: 0,
      color: colors.secondary,
      backgroundColor: "transparent",
      boxShadow: "inset 0 0 0 2px",
      ":disabled": disabled,
    },
    secondary: {
      borderRadius: 0,
      color: colors.white,
      backgroundColor: colors.secondary,
      ":disabled": disabled,
    },
    outlinePrimarySmall: {
      borderRadius: 0,
      color: colors.tertiary,
      backgroundColor: "transparent",
      boxShadow: "inset 0 0 0 2px",
      padding: "2px 9px",
      ":disabled": disabled,
    },
    outlineSecondarySmall: {
      borderRadius: 0,
      color: colors.secondary,
      backgroundColor: "transparent",
      boxShadow: "inset 0 0 0 2px",
      padding: "2px 9px",
      ":disabled": disabled,
    },
    outlineErrorSmall: {
      borderRadius: 0,
      color: colors.error,
      backgroundColor: "transparent",
      boxShadow: "inset 0 0 0 2px",
      padding: "2px 9px",
      ":disabled": disabled,
    },
  },
});
export type ExactTheme = typeof theme;
export default theme;
