import React from "react";
import { upload } from "../strings";
import { Flex, Heading, Text } from "../ui";

export default function ApplicantUploadComplete() {
  return (
    <Flex sx={{ flexDirection: "column" }} mt={2}>
      <Heading variant="heading1" mb={2}>
        {upload.complete.title}
      </Heading>
      <Text mb={2}>{upload.complete.body}</Text>
    </Flex>
  );
}
