import React from "react";
import * as api from "./api";
import { Spinner, Flex } from "./ui";
import { STATE, ApplicantQuery } from "./Query";
import ApplicationNew from "./components/ApplicationNew";
import ApplicationComplete from "./components/ApplicationComplete";
import { NotFound } from "./App";
import { PortalAuthentication } from "./model/types";
import { Message } from "@qmspringboard/shared/dist/ui";
import { PageHeader } from "./PageHeader";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { checkExhausted } from "@qmspringboard/shared/src/utils";
import { FeeStatusTypeEnum } from "@qmspringboard/shared/src/model/enums.generated";

// wrapper route which only allows contained routes to render if we are authenticated
const Loading = () => (
  <Flex sx={{ alignItems: "center", justifyContent: "center" }}>
    <Spinner size="24px" mr={2} /> Please Wait
  </Flex>
);

export default function ApplicantPage() {
  const location = useLocation();

  const [authenticated, setAuthenticated] = React.useState<null | boolean>(null);

  const [applicant, setApplicant] = React.useState<null | PortalAuthentication>(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const resource = await api.getBearerToken();
        localStorage.setItem("bearerToken", resource.data.bearerToken);
        setApplicant(resource.data);
        setAuthenticated(true);
      } catch (e) {
        console.log(e);
        setApplicant(null);
        setAuthenticated(false);
      }
    };
    fetchData();
  }, [location]);

  const hasApplicantRecord = applicant?.applicantId !== null ?? false;

  const header = applicant !== null ? <PageHeader applicant={applicant} /> : null;

  // try and fetch user, if we fail, forward to login route
  // if not, redirect to /auth?next=encoded(window.location)
  switch (authenticated) {
    case null:
      return <Spinner />;

    case false:
      return <Navigate to={`/auth/sign-in?next=${encodeURIComponent(window.location.pathname + window.location.search)}`} />;

    case true:
      return (
        <ApplicantQuery skipLoading={!hasApplicantRecord}>
          {pot => {
            let hasPortalApplication = false;
            let hasQualifications = false;
            let feeStatusType = FeeStatusTypeEnum.NoFeeStatus;
            switch (pot.state) {
              case STATE.EMPTY:
                if (!authenticated) {
                  return <Loading />;
                }
                break;
              case STATE.LOADING: {
                return <Loading />;
              }
              case STATE.ERROR: {
                return <Message variant="error">Error loading applicant</Message>;
              }
              case STATE.LOADED: {
                hasPortalApplication = pot.data.hasPortalApplication;
                hasQualifications = pot.data.hasQualifications;
                feeStatusType = pot.data.feeStatusType;
              }
            }
            return (
              <>
                {header}
                <Routes>
                  <Route
                    path="applications/new"
                    element={
                      hasPortalApplication && applicant?.applicantId != null ? (
                        <ApplicationComplete applicantId={applicant.applicantId} />
                      ) : (
                        <ApplicationNew hasApplicantRecord={hasApplicantRecord} hasQualifications={hasQualifications} feeStatusType={feeStatusType} />
                      )
                    }
                  />
                  {applicant?.applicantId != null && (
                    <Route path="applications/complete" element={<ApplicationComplete applicantId={applicant.applicantId} />} />
                  )}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </>
            );
          }}
        </ApplicantQuery>
      );

    default:
      return checkExhausted(authenticated);
  }
}
