import React from "react";
import { Checkbox as UICheckbox } from "theme-ui";

type Props = Omit<React.ComponentProps<typeof UICheckbox>, "onChange"> & {
  onChange: (state: boolean) => void;
};

export const Checkbox = ({ onChange: parentChange, ...props }: Props) => {
  const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    parentChange(e.target.checked);
  };
  return <UICheckbox onChange={onChange} {...props} />;
};
