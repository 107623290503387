import * as React from "react";
import { useCookieConsentContext } from "@use-cookie-consent/react";
import { Flex, Text, Button } from "../ui";
import { ThemeUIStyleObject } from "@qmspringboard/shared/dist/ui";

const attachToBottom: ThemeUIStyleObject = {
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  alignContent: "center",
  justifyContent: "center",
  backgroundColor: "white",
  py: 2,
};

export function CookieNotice() {
  const { consent, acceptCookies } = useCookieConsentContext();
  if (consent.statistics) {
    return null;
  }

  return (
    <Flex sx={attachToBottom}>
      <Flex
        sx={{
          px: 2,
          alignItems: "center",
        }}
      >
        <Text>
          We use cookies to improve your experience of our website. <a href="http://www.qmul.ac.uk/site/privacy/">Privacy Policy</a>
        </Text>

        <Button sx={{ ml: 3, minWidth: "80px" }} onClick={() => acceptCookies({ statistics: true })}>
          Close
        </Button>
      </Flex>
    </Flex>
  );
}
