import React from "react";
import { useEntryQualifications, useProgrammes, useSchools } from "../api";
import { SchoolCode } from "../model/types";
import { PortalQualifications } from "../model/types.generated";
import { Block, Message } from "../ui";
import { FeeCategory } from "../model/enums";
import { empty, ProgrammeAndSchoolSelect } from "@qmspringboard/portal/src/components/ProgrammeAndSchoolSelect";

interface Props {
  qualifications?: PortalQualifications;
  programmeCode: string | null;
  onChange: (value: string | null) => void;
  guessedFeeCategory?: FeeCategory | null;
  defaultSelectedSchoolCode: SchoolCode | null;
}

export function SelectValidProgramme({
  programmeCode,
  onChange,
  qualifications,
  defaultSelectedSchoolCode,
  guessedFeeCategory,
}: Props): React.ReactElement | null {
  const [school, setSchool] = React.useState<string>(defaultSelectedSchoolCode || empty);
  const programmes = useProgrammes();
  const schools = useSchools();
  const programmesWithResult = useEntryQualifications(qualifications, guessedFeeCategory);
  React.useEffect(() => {
    if (qualifications?.list.length === 0) {
      if (programmeCode !== null) onChange(null);
    }
  }, [qualifications, programmeCode, onChange]);

  // get list of valid programmes
  if (qualifications?.list.length === 0) {
    return null;
  }

  // first check programme fetch result
  switch (programmes.status) {
    case "error": {
      return <Message variant="error">Could not fetch programmes</Message>;
    }
    case "loading": {
      return <Block isBlocked={true}>Please Wait</Block>;
    }
    case "idle": {
      // we haven't fetched
      return null;
    }
  }

  switch (schools.status) {
    case "error": {
      return <Message variant="error">Could not fetch schools</Message>;
    }

    case "loading": {
      return <Block isBlocked={true}>Please Wait</Block>;
    }

    case "idle": {
      // we haven't fetched
      return null;
    }
  }

  // now overlay qualification results
  switch (programmesWithResult.status) {
    case "error": {
      return <Message variant="error">Could not fetch entry requirements</Message>;
    }

    case "loading": {
      return <Block isBlocked={true}>Please Wait</Block>;
    }

    case "idle": {
      // we haven't fetched
      return null;
    }

    case "success": {
      return (
        <ProgrammeAndSchoolSelect
          programmeCode={programmeCode}
          schoolCode={school}
          setSchoolCode={setSchool}
          onChange={onChange}
          schools={schools.data.data}
          programmes={programmes.data.data}
          requirementsCheckResult={programmesWithResult.data.data}
          guessedFeeCategory={guessedFeeCategory ?? null}
        />
      );
    }
  }
}
