import { a2Qualification } from "@qmspringboard/shared/dist/model/qualifications";
import qualificationsReducer, {
  addQualificationAction,
  ProtoQualifications,
  QualificationsAction,
  removeQualificationAction,
  updateEnglishQualificationAction,
  updateSecondaryEducationQualificationAction,
  updateQualificationAction,
} from "@qmspringboard/shared/dist/reducers/qualifications";
import currentYear from "@qmspringboard/shared/dist/utils/currentYear";
import { SecondaryEducationQualifications } from "@qmspringboard/shared/dist/components/SecondaryEducationQualifications";
import { QualificationsEnglish } from "@qmspringboard/shared/dist/components/QualificationsEnglish";
import { applicant } from "../strings";
import React from "react";
import type {
  ProgrammeCode,
  Qualification,
  Qualifications,
  SecondaryEducationQualifications as SecondaryEducationQualificationsType,
} from "../model/types";
import { Box, Button, Field, Flex, Checkbox, Heading, Label } from "../ui";
import { QualificationEditor } from "./QualificationEditor";
import { AddDefaultQualifications } from "./AddDefaultQualifications";

const preventDefault = (wrapped: (e: React.SyntheticEvent) => void) => (e: React.SyntheticEvent) => {
  e.preventDefault();
  return wrapped(e);
};

const AddQualification = ({ onClick, disabled }: { onClick: (e: React.SyntheticEvent) => void; disabled: boolean }) => (
  <Button variant="primary" onClick={preventDefault(onClick)} disabled={disabled} sx={{ width: 250 }}>
    Add Qualification
  </Button>
);

const RemoveQualification = ({ onClick }: { onClick: (e: React.SyntheticEvent) => void }) => (
  <Button sx={{ width: 150 }} variant="secondary" onClick={preventDefault(onClick)}>
    Remove
  </Button>
);

export interface IProps {
  programmeCode?: ProgrammeCode | null;
  value: ProtoQualifications;
  onChange: (value: ProtoQualifications) => void;
  maxAllowed?: number;
  showYearField?: boolean;
}

export function QualificationsEditor({ programmeCode, value, onChange: parentChange, maxAllowed = Infinity }: IProps) {
  // do not use this pattern, it can end in out of order mutations because value is not necessarily up-to-date
  const dispatch = React.useMemo(() => (action: QualificationsAction) => parentChange(qualificationsReducer(value, action)), [value, parentChange]);

  const [showYearField, setShowYearField] = React.useState(false);

  const add = () => {
    if (value.list.length >= maxAllowed) {
      return;
    }

    const lastQualification = value.list[value.list.length - 1];

    const qualification =
      lastQualification == null ? a2Qualification(null, null, currentYear()) : { ...lastQualification, subject: null, grade: null };

    dispatch(addQualificationAction(qualification));
  };

  const remove = (index: number) => {
    dispatch(removeQualificationAction(index));
  };

  const change = (index: number) => (qualification: Qualification) => dispatch(updateQualificationAction(index, qualification));

  const setEnglish = (newValue: Qualifications["english"]) => {
    return dispatch(updateEnglishQualificationAction(newValue));
  };

  const setSecondaryEducation = (newValue: SecondaryEducationQualificationsType) => dispatch(updateSecondaryEducationQualificationAction(newValue));

  if (value.list.length === 0) {
    return <AddDefaultQualifications value={value} onChange={parentChange} programmeCode={programmeCode} />;
  }

  return (
    <>
      <Box mb={2}>
        <Label>
          <Checkbox checked={showYearField} onChange={setShowYearField} mr={2} />
          {applicant.qualificationsOtherYear}
        </Label>
      </Box>
      {value.list.map((qualification, i) => (
        <Field key={i} name={`qualifications.list[${i}]`}>
          {({ htmlProps }) => (
            <Flex
              sx={{
                alignItems: "top",
                mt: 2,
                flexWrap: "wrap",
              }}
            >
              <QualificationEditor namespace={htmlProps.name} showYearField={showYearField} value={qualification} onChange={change(i)} />
              <Box sx={{ flex: "auto" }} />
              <Box>
                <RemoveQualification onClick={() => remove(i)} />
              </Box>
            </Flex>
          )}
        </Field>
      ))}
      <AddQualification disabled={value.list.length >= maxAllowed} onClick={() => add()} />
      <Heading variant="heading2" mt={2} mb={2}>
        {applicant.additionalQualifications}
      </Heading>
      <Box bg="grey" mb={3} p={2}>
        {applicant.additionalQualificationsDescription}
      </Box>
      <SecondaryEducationQualifications
        applicantStrings={applicant}
        value={value.secondaryEducation}
        onChange={setSecondaryEducation}
        namespace={`qualifications.secondaryEducation`}
      />
      {value.secondaryEducation ? (
        <QualificationsEnglish applicantStrings={applicant} value={value.english} onChange={setEnglish} namespace={`qualifications.english`} />
      ) : null}
    </>
  );
}
