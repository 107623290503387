import React from "react";
import { Select as HTMLSelect } from "theme-ui";

type SelectProps = React.ComponentProps<typeof HTMLSelect>;
type Override<T, U> = Omit<T, keyof U> & U;

export type ISelectOption<AllowedValue = string | null | undefined> = {
  value: AllowedValue;
  disabled?: boolean;
  label: string; // simple for now
};

type LocalProps<AllowedValue = string> = {
  value: AllowedValue;
  onChange: (value: AllowedValue) => void;
  options: ISelectOption<AllowedValue>[];
};

export type IProps<AllowedValue> = Override<SelectProps, LocalProps<AllowedValue>>;

export function Select<AllowedType extends string | null | undefined>({ options, onChange: parentChange, value, ...props }: IProps<AllowedType>) {
  // internally use index
  const selectedIndex = options.findIndex(o => o.value === value);
  const htmlOptions = options.map(({ disabled, label }, i) => (
    <option disabled={disabled} key={i} value={i}>
      {label}
    </option>
  ));

  const onChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const newIndex = Number(e.currentTarget.value);
    parentChange(options[newIndex].value);
  };

  return (
    <HTMLSelect onChange={onChange} value={selectedIndex} {...props}>
      {htmlOptions}
    </HTMLSelect>
  );
}
