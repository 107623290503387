import { unsafeTag, untag } from "./tagged";
import { ProgrammeCode } from "./types";

export function programmeCodeValid(code: ProgrammeCode): boolean {
  return /^[A-Z0-9]{4}-[A-Z0-9]+$/.test(code);
}

export function programmeCodeToString(code: ProgrammeCode): string {
  return untag(code);
}

export function unsafeStringToProgrammeCode(code: string): ProgrammeCode {
  return unsafeTag<"ProgrammeCode">(code);
}

// Also exported from programme.js:
export function programmeCodeToUcasCourseCode(code: ProgrammeCode): string {
  return code.split("-")[0];
}

// Also exported from programme.js:
export function programmeCodeToRouteCode(code: ProgrammeCode): string {
  return code.split("-")[1];
}

export function programmeCodeToR900Label(code: ProgrammeCode): string {
  const ucas = programmeCodeToUcasCourseCode(code);

  const showRouteCodeInLabel = (ucas: string) => ucas === "R900";

  return showRouteCodeInLabel(ucas) ? code : ucas;
}
