import { A2GradeEnum, ASGradeEnum, BtecGradeEnum, ExtendedProjectGradeEnum, IBGradeEnum } from "@qmspringboard/shared/dist/model/enums.generated";
import { isQualificationType, Qualification } from "@qmspringboard/shared/dist/model/qualifications";
import { splitBtecGrades } from "@qmspringboard/shared/dist/model/requirements";
import { checkExhausted } from "@qmspringboard/shared/dist/utils";
import React from "react";
import type { ThemeUIStyleObject } from "@qmspringboard/shared/dist/ui";
import { applicant } from "../strings";
import NullableStringInput, { IProps as INullableStringInputProps, IValue } from "@qmspringboard/shared/dist/ui/NullableStringInput";
import { Select } from "../ui";

export type IProps = {
  type: Qualification["tpe"];
  value: Qualification["grade"];
  onChange: (newValue: Qualification["grade"]) => void;
  sx?: ThemeUIStyleObject;
};

const ConstrainedTextField = NullableStringInput;

const IntegerGradeField = ({ max, placeholder, ...rest }: INullableStringInputProps) => (
  <NullableStringInput inputMode="numeric" max={max} placeholder={placeholder ?? `Out of ${max}`} {...rest} />
);

const NumberGradeField = ({ max, placeholder, ...rest }: INullableStringInputProps) => (
  <NullableStringInput inputMode="numeric" max={max} placeholder={placeholder ?? `Out of ${max}.0`} {...rest} />
);

export default function GradeEditor({ type, ...rest }: IProps): React.ReactElement {
  const handleBtecChange = (newGrades: IValue) => {
    const grades = splitBtecGrades(newGrades || "");
    rest.onChange(grades.join(""));
  };

  if (isQualificationType(type)) {
    switch (type) {
      case "A2":
        return <Select {...rest} options={A2GradeEnum.dropdownOptionsWithBlank(false, applicant.qualificationPlaceholderGrade)} />;

      case "AS":
        return <Select {...rest} options={ASGradeEnum.dropdownOptionsWithBlank(false, applicant.qualificationPlaceholderGrade)} />;

      case "EPQ":
        return <Select {...rest} options={ExtendedProjectGradeEnum.dropdownOptionsWithBlank(false, applicant.qualificationPlaceholderGrade)} />;

      case "IBOverall":
        return <IntegerGradeField {...rest} max={45} />;

      case "IBHigher":
      case "IBStandard":
        return <Select {...rest} options={IBGradeEnum.dropdownOptionsWithBlank(false, applicant.qualificationPlaceholderGrade)} />;

      case "BtecExtendedRqf":
      case "BtecExtendedQcf":
        return <ConstrainedTextField {...rest} onChange={handleBtecChange} placeholder="e.g. DDD" />;

      case "BtecNationalRqf":
      case "BtecNationalQcf":
        return <ConstrainedTextField {...rest} onChange={handleBtecChange} placeholder="e.g. DD" />;

      case "BtecSubsidiaryRqf":
      case "BtecSubsidiaryQcf":
        return <Select {...rest} options={BtecGradeEnum.dropdownOptionsWithBlank(false, applicant.qualificationPlaceholderGrade)} />;

      case "FrenchBacOverall":
      case "FrenchBac":
        return <IntegerGradeField {...rest} max={20} />;

      case "IndianHsNatOverall":
      case "IndianHsWbOverall":
      case "IndianHsOtherOverall":
      case "IndianHsNat":
      case "IndianHsWb":
      case "IndianHsOther":
        return <IntegerGradeField {...rest} max={100} />;

      case "GermanAbiturOverall":
        return <NumberGradeField {...rest} max={6} />;
      case "GermanAbitur":
        return <IntegerGradeField {...rest} max={15} />;

      case "PolishMatura":
        return <IntegerGradeField {...rest} max={100} />;

      case "SpanishBacOverall":
        return <NumberGradeField {...rest} max={10} />;
      case "SpanishBac":
        return <IntegerGradeField {...rest} max={10} />;

      case "ItalianDiplomaOverall":
        return <IntegerGradeField {...rest} max={100} />;
      case "ItalianDiploma":
        return <IntegerGradeField {...rest} max={10} />;

      case "UsDiplomaOverall":
        return <NumberGradeField {...rest} max={4} />;
      case "UsDiploma":
        return <IntegerGradeField {...rest} max={5} />;

      case "UCATScore":
        return <IntegerGradeField {...rest} max={3600} />;
      case "UCATBand":
        return <IntegerGradeField {...rest} min={1} max={4} />;

      default:
        return checkExhausted(type);
    }
  } else {
    return <ConstrainedTextField {...rest} />;
  }
}
