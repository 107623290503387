import React from "react";
import { home, offline } from "./strings";
import { Flex, Heading, Text } from "./ui";

export interface EmbargoProps {
  until: Date;
}

export function Embargo(props: EmbargoProps) {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading variant="heading1">{home.title}</Heading>
      <Flex mb={2}>
        <Text>{offline(props.until).body}</Text>
      </Flex>
    </Flex>
  );
}
