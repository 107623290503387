import { Box, Flex } from "./ui";
import React from "react";

const breakpoints = ["100%", "auto"];

export function PageHeader({
  applicant,
}: {
  applicant: {
    email?: string;
    ucasPersonalId?: string | null;
  };
}) {
  return (
    <Flex bg="grey" p={2} sx={{ justifyContent: "space-between", flexWrap: "wrap" }}>
      {applicant.email && <Box sx={{ width: breakpoints }}>Email: {applicant.email}</Box>}
      {applicant.ucasPersonalId && <Box sx={{ width: breakpoints }}>UCAS ID: {applicant.ucasPersonalId}</Box>}
      <Box sx={{ width: breakpoints }}>
        <a href="/portal/auth/signout">Sign out</a>
      </Box>
    </Flex>
  );
}
