import { Container, Flex, Text } from "theme-ui";
import * as React from "react";
import { Spinner } from "theme-ui";

interface IProps {
  isBlocked: boolean;
  children: React.ReactNode;
  label?: React.ReactNode;
  containerProps?: React.ComponentProps<typeof Flex>;
}

export const Block = ({ isBlocked, children, label, containerProps }: IProps) => {
  return (
    <Flex
      sx={{
        position: "relative",
        ...containerProps?.sx,
      }}
      {...containerProps}
    >
      {isBlocked ? (
        <Container
          variant="overlay"
          sx={{
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Spinner size="32px" radius="4px" />
          {label ? <Text>{label}</Text> : null}
        </Container>
      ) : null}
      {children}
    </Flex>
  );
};
