import React from "react";
import { Input } from "theme-ui";

export type IValue = string | null | undefined;

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type Override<T, U> = Omit<T, keyof U> & U;
type InputProps = React.ComponentProps<typeof Input>;

type LocalProps = {
  value: IValue;
  onChange: (value: IValue) => void;
};

export type IProps = Override<InputProps, LocalProps>;

export const NullableStringInput = ({ onChange: parentChange, value, ...props }: IProps) => {
  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    if (val === "") {
      parentChange(null);
    } else {
      parentChange(val);
    }
  };
  return <Input disabled={props.readOnly} onChange={onChange} value={value === null ? "" : value} {...props} />;
};

export default NullableStringInput;
