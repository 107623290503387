import { Application } from "../model/applications.generated";
import currentYear from "./currentYear";

/*
We can make offers for those who will turn 18 in their first year of study 
with the exception of the following programmes:

- BSc Biomedical Science (not Biological Sciences with Foundation) - B990-USBMS
- BSc Psychology (not Biological Sciences with Foundation) - C800-USPSY

For those two, you need to be 18 to apply.
*/
const progsRestrictedTo18YearOldsAtApplication = ["C800-USPSY", "B990-USBMS"];

export function isOldEnoughToApplyToCourse(dateOfBirth: Date, currentYear: number, prog?: string): boolean {
  const birthYear = dateOfBirth.getFullYear();
  const birthMonth = dateOfBirth.getMonth() + 1;
  const birthDay = dateOfBirth.getDate();

  const mustBe18ToApply = prog ? progsRestrictedTo18YearOldsAtApplication.includes(prog) : false;

  const minBirthday = mustBe18ToApply ? new Date(`${currentYear - 18}-09-01`) : new Date(`${currentYear - 17}-08-31`);

  const minYear = minBirthday.getFullYear();
  const minMonth = minBirthday.getMonth() + 1;
  const minDay = minBirthday.getDate();

  const canApply =
    birthYear < minYear ||
    (birthYear === minYear && birthMonth < minMonth) ||
    (birthYear === minYear && birthMonth === minMonth && birthDay <= minDay);

  return canApply;
}

// In Springboard we check all the applicant's programmes:
export function ageRestrictionMessage(applications: Application[], dateOfBirth?: string | null): string | null {
  if (!dateOfBirth) {
    return null;
  }

  const dob = new Date(dateOfBirth);
  const thisYear = currentYear();

  const restrictedProg: string | undefined = applications.find(app =>
    progsRestrictedTo18YearOldsAtApplication.includes(app.programmeCode),
  )?.programmeCode;

  if (restrictedProg && isOldEnoughToApplyToCourse(dob, thisYear, restrictedProg) === false) {
    return "The applicant must be 18, at the start of the academic year, to apply for this programme.";
  } else if (isOldEnoughToApplyToCourse(dob, thisYear) === false) {
    return "The applicant must be 18, by the end of their first year of study at Queen Mary, to apply.";
  } else {
    return null;
  }
}

export function formatAgeConcernPortal(prog?: string): string {
  return prog && progsRestrictedTo18YearOldsAtApplication.includes(prog)
    ? "You must be 18, at the start of the academic year, to apply for this programme."
    : "You must be 18, by the end of your first year of study at Queen Mary, to apply.";
}
