import { CookieConsentProvider } from "@use-cookie-consent/react";
import React from "react";
import { Container, ThemeProvider } from "@qmspringboard/shared/dist/ui";
import { QueryClient, QueryClientProvider } from "react-query";
import theme from "./theme";
import { Box, Heading } from "./ui";
import { CookieNotice } from "./components/CookieNotice";
import { BrowserRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { AppRoutes } from "@qmspringboard/portal/src/AppRoutes";

const queryClient = new QueryClient();

export const NotFound = (props: { message?: string }) => (
  <Box>
    <Heading variant="heading1">Page Not Found</Heading>
    <Box>{props.message ? props.message : "Sorry, that page does not exist"}</Box>
  </Box>
);

const App: React.FC = () => {
  return (
    <CookieConsentProvider>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <Container variant="page" id="container-root">
                <AppRoutes />
                <CookieNotice />
              </Container>
            </ThemeProvider>
          </QueryClientProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </CookieConsentProvider>
  );
};

export default App;
