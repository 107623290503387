/* eslint-disable no-mixed-operators */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { Tagged, unsafeIsTagged } from "./tagged";

export type Subject = string;

export type ASGrade = "A" | "B" | "C" | "D" | "E" | "NU";

export type ExtendedProjectGrade = "A*" | "A" | "B" | "C" | "D" | "E";

export type A2Grade = "A*" | "A" | "B" | "C" | "D" | "E" | "NU";

export type IBGrade = "7" | "6" | "5" | "4" | "3" | "2" | "1" | "N";

export type BtecGrade = "D*" | "D" | "M" | "P" | "N";

export type GcseGrade = "9" | "A*" | "8" | "A" | "7" | "6" | "B" | "5" | "C" | "4" | "D" | "3" | "E" | "2" | "F" | "1" | "G" | "U";

export type QualificationBoard = "A2" | "IB" | "BtecRqf" | "BtecQcf" | "FB" | "GA" | "IHSN" | "IHSWB" | "IHSO" | "ID" | "PM" | "SB" | "USD" | "UCAT";

export type QualificationType =
  | "A2"
  | "AS"
  | "EPQ"
  | "IBOverall"
  | "IBHigher"
  | "IBStandard"
  | "BtecExtendedRqf"
  | "BtecNationalRqf"
  | "BtecSubsidiaryRqf"
  | "BtecExtendedQcf"
  | "BtecNationalQcf"
  | "BtecSubsidiaryQcf"
  | "FrenchBacOverall"
  | "FrenchBac"
  | "IndianHsNatOverall"
  | "IndianHsNat"
  | "IndianHsWbOverall"
  | "IndianHsWb"
  | "IndianHsOtherOverall"
  | "IndianHsOther"
  | "GermanAbiturOverall"
  | "GermanAbitur"
  | "PolishMatura"
  | "SpanishBacOverall"
  | "SpanishBac"
  | "ItalianDiplomaOverall"
  | "ItalianDiploma"
  | "UsDiplomaOverall"
  | "UsDiploma"
  | "UCATScore"
  | "UCATBand";

export interface Qualification {
  tpe: QualificationType | string;
  subject?: string | null;
  grade?: string | null;
  yearObtained?: number | null;
}

export interface GcseEnglishQualification {
  grade?: GcseGrade | null;
}

export interface IeltsEnglishQualification {
  overall?: number | null;
  listening?: number | null;
  reading?: number | null;
  writing?: number | null;
  speaking?: number | null;
}

export interface ToeflEnglishQualification {
  overall?: number | null;
  listening?: number | null;
  reading?: number | null;
  writing?: number | null;
  speaking?: number | null;
}

export interface OtherEnglishQualification {}

export type EnglishQualification =
  | ({ type: "Gcse" } & GcseEnglishQualification)
  | ({ type: "Ielts" } & IeltsEnglishQualification)
  | ({ type: "Toefl" } & ToeflEnglishQualification)
  | ({ type: "Other" } & OtherEnglishQualification);

export interface Gcse {
  has5orMoreAtC4OrAbove: boolean;
  hasMathsAtB5OrAbove: boolean;
  has6orMoreAt6and7: boolean;
}

export interface Other {}

export type SecondaryEducationQualifications = ({ type: "Gcse" } & Gcse) | ({ type: "Other" } & Other);

export interface Qualifications {
  list: Qualification[];
  english?: EnglishQualification | null;
  secondaryEducation?: SecondaryEducationQualifications | null;
  version: number;
}

export interface InternationalEquiv {
  boardName: string;
  subjects: [A2Grade, string][];
  overall: [A2Grade[], string][] | null;
}

export const isSubject = (v: any): v is Subject => {
  return typeof v === "string";
};

export const isASGrade = (v: any): v is ASGrade => {
  return v === "A" || v === "B" || v === "C" || v === "D" || v === "E" || v === "NU";
};

export const isExtendedProjectGrade = (v: any): v is ExtendedProjectGrade => {
  return v === "A*" || v === "A" || v === "B" || v === "C" || v === "D" || v === "E";
};

export const isA2Grade = (v: any): v is A2Grade => {
  return v === "A*" || v === "A" || v === "B" || v === "C" || v === "D" || v === "E" || v === "NU";
};

export const isIBGrade = (v: any): v is IBGrade => {
  return v === "7" || v === "6" || v === "5" || v === "4" || v === "3" || v === "2" || v === "1" || v === "N";
};

export const isBtecGrade = (v: any): v is BtecGrade => {
  return v === "D*" || v === "D" || v === "M" || v === "P" || v === "N";
};

export const isGcseGrade = (v: any): v is GcseGrade => {
  return (
    v === "9" ||
    v === "A*" ||
    v === "8" ||
    v === "A" ||
    v === "7" ||
    v === "6" ||
    v === "B" ||
    v === "5" ||
    v === "C" ||
    v === "4" ||
    v === "D" ||
    v === "3" ||
    v === "E" ||
    v === "2" ||
    v === "F" ||
    v === "1" ||
    v === "G" ||
    v === "U"
  );
};

export const isQualificationBoard = (v: any): v is QualificationBoard => {
  return (
    v === "A2" ||
    v === "IB" ||
    v === "BtecRqf" ||
    v === "BtecQcf" ||
    v === "FB" ||
    v === "GA" ||
    v === "IHSN" ||
    v === "IHSWB" ||
    v === "IHSO" ||
    v === "ID" ||
    v === "PM" ||
    v === "SB" ||
    v === "USD" ||
    v === "UCAT"
  );
};

export const isQualificationType = (v: any): v is QualificationType => {
  return (
    v === "A2" ||
    v === "AS" ||
    v === "EPQ" ||
    v === "IBOverall" ||
    v === "IBHigher" ||
    v === "IBStandard" ||
    v === "BtecExtendedRqf" ||
    v === "BtecNationalRqf" ||
    v === "BtecSubsidiaryRqf" ||
    v === "BtecExtendedQcf" ||
    v === "BtecNationalQcf" ||
    v === "BtecSubsidiaryQcf" ||
    v === "FrenchBacOverall" ||
    v === "FrenchBac" ||
    v === "IndianHsNatOverall" ||
    v === "IndianHsNat" ||
    v === "IndianHsWbOverall" ||
    v === "IndianHsWb" ||
    v === "IndianHsOtherOverall" ||
    v === "IndianHsOther" ||
    v === "GermanAbiturOverall" ||
    v === "GermanAbitur" ||
    v === "PolishMatura" ||
    v === "SpanishBacOverall" ||
    v === "SpanishBac" ||
    v === "ItalianDiplomaOverall" ||
    v === "ItalianDiploma" ||
    v === "UsDiplomaOverall" ||
    v === "UsDiploma" ||
    v === "UCATScore" ||
    v === "UCATBand"
  );
};

export const isQualification = (v: any): v is Qualification => {
  return (
    typeof v === "object" &&
    v != null &&
    "tpe" in v &&
    (isQualificationType(v.tpe) || typeof v.tpe === "string") &&
    (!("subject" in v) || typeof v.subject === "string" || v.subject === null) &&
    (!("grade" in v) || typeof v.grade === "string" || v.grade === null) &&
    (!("yearObtained" in v) || typeof v.yearObtained === "number" || v.yearObtained === null)
  );
};

export const isGcseEnglishQualification = (v: any): v is GcseEnglishQualification => {
  return typeof v === "object" && v != null && (!("grade" in v) || isGcseGrade(v.grade) || v.grade === null);
};

export const isIeltsEnglishQualification = (v: any): v is IeltsEnglishQualification => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("overall" in v) || typeof v.overall === "number" || v.overall === null) &&
    (!("listening" in v) || typeof v.listening === "number" || v.listening === null) &&
    (!("reading" in v) || typeof v.reading === "number" || v.reading === null) &&
    (!("writing" in v) || typeof v.writing === "number" || v.writing === null) &&
    (!("speaking" in v) || typeof v.speaking === "number" || v.speaking === null)
  );
};

export const isToeflEnglishQualification = (v: any): v is ToeflEnglishQualification => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("overall" in v) || typeof v.overall === "number" || v.overall === null) &&
    (!("listening" in v) || typeof v.listening === "number" || v.listening === null) &&
    (!("reading" in v) || typeof v.reading === "number" || v.reading === null) &&
    (!("writing" in v) || typeof v.writing === "number" || v.writing === null) &&
    (!("speaking" in v) || typeof v.speaking === "number" || v.speaking === null)
  );
};

export const isOtherEnglishQualification = (v: any): v is OtherEnglishQualification => {
  return typeof v === "object" && v != null;
};

export const isEnglishQualification = (v: any): v is EnglishQualification => {
  return (
    (typeof v === "object" && v != null && "type" in v && v.type === "Gcse" && isGcseEnglishQualification(v)) ||
    (typeof v === "object" && v != null && "type" in v && v.type === "Ielts" && isIeltsEnglishQualification(v)) ||
    (typeof v === "object" && v != null && "type" in v && v.type === "Toefl" && isToeflEnglishQualification(v)) ||
    (typeof v === "object" && v != null && "type" in v && v.type === "Other" && isOtherEnglishQualification(v))
  );
};

export const isGcse = (v: any): v is Gcse => {
  return (
    typeof v === "object" &&
    v != null &&
    "has5orMoreAtC4OrAbove" in v &&
    typeof v.has5orMoreAtC4OrAbove === "boolean" &&
    "hasMathsAtB5OrAbove" in v &&
    typeof v.hasMathsAtB5OrAbove === "boolean" &&
    "has6orMoreAt6and7" in v &&
    typeof v.has6orMoreAt6and7 === "boolean"
  );
};

export const isOther = (v: any): v is Other => {
  return typeof v === "object" && v != null;
};

export const isSecondaryEducationQualifications = (v: any): v is SecondaryEducationQualifications => {
  return (
    (typeof v === "object" && v != null && "type" in v && v.type === "Gcse" && isGcse(v)) ||
    (typeof v === "object" && v != null && "type" in v && v.type === "Other" && isOther(v))
  );
};

export const isQualifications = (v: any): v is Qualifications => {
  return (
    typeof v === "object" &&
    v != null &&
    "list" in v &&
    Array.isArray(v.list) &&
    v.list.every((i: any) => isQualification(i)) &&
    (!("english" in v) || isEnglishQualification(v.english) || v.english === null) &&
    (!("secondaryEducation" in v) || isSecondaryEducationQualifications(v.secondaryEducation) || v.secondaryEducation === null) &&
    "version" in v &&
    typeof v.version === "number"
  );
};

export const isInternationalEquiv = (v: any): v is InternationalEquiv => {
  return (
    typeof v === "object" &&
    v != null &&
    "boardName" in v &&
    typeof v.boardName === "string" &&
    "subjects" in v &&
    Array.isArray(v.subjects) &&
    v.subjects.every((i: any) => Array.isArray(i) && i.length === 2 && isA2Grade(i[0]) && typeof i[1] === "string") &&
    "overall" in v &&
    ((Array.isArray(v.overall) &&
      v.overall.every(
        (i: any) => Array.isArray(i) && i.length === 2 && Array.isArray(i[0]) && i[0].every((i: any) => isA2Grade(i)) && typeof i[1] === "string",
      )) ||
      v.overall === null)
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable no-mixed-operators */
