import { QualificationBoardEnum, QualificationTypeEnum } from "@qmspringboard/shared/dist/model/enums.generated";
import { Qualification, QualificationBoard, templateQualifications } from "@qmspringboard/shared/dist/model/qualifications";
import qualificationsReducer, {
  prefillQualificationsAction,
  ProtoQualifications,
  QualificationsAction,
} from "@qmspringboard/shared/dist/reducers/qualifications";
import React from "react";
import { isUcatCourseCode } from "../utils/ucatHelpers";
import { applicant } from "../strings";
import { Box, Button, Flex, ThemeUIStyleObject } from "../ui";
import { ProgrammeCode } from "../model/types";
import currentYear from "@qmspringboard/shared/src/utils/currentYear";

export interface IProps {
  value: ProtoQualifications;
  onChange: (value: ProtoQualifications) => void;
  programmeCode?: ProgrammeCode | null;
}

const buttonSx: ThemeUIStyleObject = {
  mb: 2,
  mr: 2,
  minWidth: ["100%", "auto"],
};

export function AddDefaultQualifications({ value, onChange, programmeCode }: IProps): React.ReactElement {
  const dispatch = React.useMemo(() => (action: QualificationsAction) => onChange(qualificationsReducer(value, action)), [value, onChange]);

  const handlePrefill = (board: QualificationBoard) => () => {
    // special case if we are coming in on a UCAT programme code:
    const qualifications = templateQualifications(board);
    if (isUcatCourseCode(programmeCode)) {
      const extra = templateQualifications(QualificationBoardEnum.UCAT);
      qualifications.push(...extra);
    }
    prefill(qualifications);
  };

  const prefill = React.useCallback((template: Qualification[]) => dispatch(prefillQualificationsAction(template)), [dispatch]);

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Box mb={2}>{applicant.qualificationFillType}</Box>
      <Flex sx={{ flexWrap: "wrap" }}>
        <Button sx={buttonSx} variant="primary" onClick={handlePrefill(QualificationBoardEnum.ALevel)}>
          A-Levels
        </Button>
        <Button sx={buttonSx} variant="primary" onClick={handlePrefill(QualificationBoardEnum.IB)}>
          International Baccalaureate
        </Button>
        <Button sx={buttonSx} variant="primary" onClick={handlePrefill(QualificationBoardEnum.BtecQcf)}>
          BTEC
        </Button>
        <Button sx={buttonSx} variant="primary" onClick={() => prefill([{ tpe: QualificationTypeEnum.A2, yearObtained: currentYear() }])}>
          Not Listed
        </Button>
      </Flex>
    </Flex>
  );
}
