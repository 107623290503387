import * as React from "react";
import { Message } from "../model/types";
import { FieldError } from "../ui";

export class MessageError extends Error {
  data: Message;
  constructor(data: Message) {
    super();
    this.data = data;
    this.name = "MesageError";
  }
}

export function Messages({ messages }: { messages: Message[] }) {
  return (
    <>
      {messages && messages.length > 0
        ? messages.map((message, i) => (
            <FieldError key={i} type={message.level} title={message.path.join(".")}>
              {message.text}
            </FieldError>
          ))
        : null}
    </>
  );
}
