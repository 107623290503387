import { Box, Text, Heading } from "../ui";
import { verify } from "../strings";
import React from "react";
import { Link } from "react-router-dom";

export default function Magic() {
  return (
    <Box>
      <Heading variant="heading1">{verify.title}</Heading>
      <Text>
        {verify.body}
        <Link to="/auth/sign-in">{verify.linkText}</Link>
      </Text>
    </Box>
  );
}
