import React from "react";
import { badLink } from "./strings";
import { Box, Flex, Heading, LinkButton, Text } from "./ui";

export default function BadLink() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading variant="heading1">{badLink.title}</Heading>
      <Flex mb={2}>
        <Text>{badLink.body}</Text>
      </Flex>
      <Flex mr={2}>
        <Box>
          <LinkButton to={`/applicant/applications/new`} variant="primary">
            {badLink.buttonText}
          </LinkButton>
        </Box>
      </Flex>
    </Flex>
  );
}
