// We want residency options A and B to also include a hint as to the date three years ago.
// Examples for 2023:
//   3 years or more – prior to the 1st September 2020
//   Less than 3 years – after the 1st September 2020

import { UkResidencyEnum } from "./enums.generated";

// This function to save us having to remember to update the text each year:
export const appendResidencyLabelWithDateHint = (value: string | null, label: string) => {
  const threeYearsAgo = new Date().getFullYear() - 3;
  switch (value) {
    case UkResidencyEnum.A:
      return `${label} - prior to the 1st September ${threeYearsAgo}`;
    case UkResidencyEnum.B:
      return `${label} - after the 1st September ${threeYearsAgo}`;
    default:
      return label;
  }
};
