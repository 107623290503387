import format from "date-fns-tz/format";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import setHours from "date-fns/setHours";
import formatInTimezone from "date-fns-tz/formatInTimeZone";
import { Tagged, unsafeTag } from "../model/tagged";

export type TimeZone = Tagged<"TimeZone">;

// date-fns formatting string
// https://date-fns.org/v2.19.0/docs/format
export type TimestampFormat = Tagged<"TimestampFormat">;

export function unsafeTimeZone(str: string): TimeZone {
  return unsafeTag(str);
}

export function unsafeTimestampFormat(str: string): TimestampFormat {
  return unsafeTag(str);
}

export const defaultTimeZone = unsafeTimeZone("Europe/London");

export const utc = unsafeTimeZone("UTC");

export const ddmm = unsafeTimestampFormat("dd/MM");

export const ddmmyyyy = unsafeTimestampFormat("dd/MM/yyyy");

export const yyyymmdd = unsafeTimestampFormat("yyyy-MM-dd");

export const hhmm = unsafeTimestampFormat("HH:mm");

export const ddmmhhmm = unsafeTimestampFormat(`${ddmm} ${hhmm}`);

export const ddmmyyyyhhmm = unsafeTimestampFormat(`${ddmmyyyy} ${hhmm}`);

export const isoTimestampFormat = unsafeTimestampFormat("yyyy-MM-dd'T'kk:mm:ss'Z'");

export function formatIso(date: Date): string {
  return format(utcToZonedTime(date, "UTC"), isoTimestampFormat);
}

export function parseIso(iso: string): Date {
  return parseISO(iso);
}

export function formatLocal(date: Date, fmt: TimestampFormat): string {
  return format(date, fmt);
}

export function formatLondon(date: Date, fmt: TimestampFormat): string {
  return formatInTimezone(date, "Europe/London", fmt);
}

export function parseLocal(str: string, fmt: TimestampFormat): Date {
  return parse(str, fmt, new Date());
}

export function setEuropeLondonHours(date: Date, hours: number): Date {
  return setHours(utcToZonedTime(date, "Europe/London"), hours);
}
