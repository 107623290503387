import React from "react";
import { Text } from "theme-ui";

export function FieldError({
  type,
  ...props
}: {
  type: "error" | "warning";
} & React.ComponentProps<typeof Text>) {
  return <Text variant={type} {...props} />;
}
