import { Message } from "./model/core.generated";

export class AppError {
  message: string;

  constructor(message: string) {
    this.message = message;
  }

  allMessages() {
    return this.message;
  }
}

export class NotFoundError extends AppError {
  itemType: string;
  item: string;

  constructor(itemType: string, item: string) {
    super(`Not found: ${itemType} ${item}`);
    this.itemType = itemType;
    this.item = item;
  }
}

export class ConcurrentModificationError extends AppError {
  itemType: string;
  item: string;

  constructor(itemType: string, item: string) {
    super(`Concurrent modification: ${itemType} ${item}`);
    this.itemType = itemType;
    this.item = item;
  }
}

export class ValidationError extends AppError {
  messages: Message[];

  constructor(messages: Message[]) {
    super(`Validation error`);
    this.messages = messages;
  }

  allMessages() {
    return this.messages.map(msg => msg.text).join(" ");
  }
}

export class UnauthorizedError extends AppError {}
export class UnknownError extends AppError {}
export class UnknownClientError extends UnknownError {}
export class UnknownServerError extends UnknownError {}
