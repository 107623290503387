import * as React from "react";
type Props = {
  size?: number | string;
};

export function DownArrow({ size }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={"0 0 24 24"} fill="currentColor">
      <path d="M7 10l5 5 5-5z" />
    </svg>
  );
}
