import { matchSorter } from "match-sorter";
import { Opt } from "../utils";

type Subject = string;
// FIXME: generate subjects type from API
export type Subjects = {
  common: Subject[];

  // subject lists that override common subjects for specific qualification types
  qualTypeSubjects: {
    qualificationType: string;
    subjects: Subject[];
  }[];

  subjectAliases: {
    qualificationType: string;
    subjects: Subject[];
  }[];
};

export function createValidSubjectOptions(qualification: string, subjects: Subjects): Subject[] {
  const selectedSubjects: Subject[] = (() => {
    // if we have a qualTypeSubjects for this qualification *only* use those:
    const alternateSubjects = subjects.qualTypeSubjects.find(r => qualification === r.qualificationType);
    if (alternateSubjects) {
      return alternateSubjects.subjects;
    }

    // find extra subjects related to the currently picked qualification type
    const extra = subjects.subjectAliases.find(r => qualification === r.qualificationType);

    if (extra) {
      return [...subjects.common, ...extra.subjects];
    }

    return subjects.common;
  })();
  return selectedSubjects.sort((a, b) => {
    return a.localeCompare(b);
  });
}

export const filterSubjectsByString = (subjects: Subject[]) => (value: Opt<string>) => {
  return value ? matchSorter(subjects, value) : subjects;
};
