import * as React from "react";
import { Qualifications, SecondaryEducationQualifications } from "../model/qualifications";
import { ApplicantStrings } from "../strings";

import { Field, Flex, Checkbox, Label, Select, FieldChildrenProps, ISelectOption } from "../ui";
import { assertNever } from "../utils";

type Props = React.PropsWithChildren<{
  applicantStrings: ApplicantStrings;
  namespace: string;
  value: Qualifications["secondaryEducation"];
  onChange: (newValue: SecondaryEducationQualifications) => void;
  readOnly?: boolean;
}>;

const didGCSEDropdown: ISelectOption<null | SecondaryEducationQualifications["type"]>[] = [
  { label: "Choose", value: null, disabled: true },
  { label: "GCSEs", value: "Gcse" },
  { label: "Other", value: "Other" },
];

export function SecondaryEducationQualifications({ value, applicantStrings, namespace, onChange, readOnly }: Props) {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Field sx={{ flex: 1 }} name={namespace} disabled={readOnly} label={applicantStrings.qualificationTookGCSE}>
        {({ htmlProps }: FieldChildrenProps) => (
          <Select
            {...htmlProps}
            sx={{ width: ["100%", "40%"] }}
            options={didGCSEDropdown}
            value={value ? value.type : null}
            onChange={newValue => {
              switch (newValue) {
                case "Gcse": {
                  onChange({
                    type: "Gcse",
                    has5orMoreAtC4OrAbove: false,
                    hasMathsAtB5OrAbove: false,
                    has6orMoreAt6and7: false,
                  });
                  break;
                }
                case "Other": {
                  onChange({
                    type: "Other",
                  });
                  break;
                }
                case null: {
                  throw new Error("picked null somehow...");
                }
                default: {
                  assertNever(newValue);
                }
              }
            }}
          />
        )}
      </Field>
      {value && value.type === "Gcse" ? (
        <>
          <Field name={`${namespace}.has5orMoreAtC4OrAbove`} disabled={readOnly} sx={{ my: 8 }}>
            {({ htmlProps }: FieldChildrenProps) => (
              <Label>
                <Checkbox
                  {...htmlProps}
                  checked={value.has5orMoreAtC4OrAbove}
                  onChange={checked => {
                    if (checked) {
                      onChange({
                        ...value,
                        has5orMoreAtC4OrAbove: true,
                      });
                    } else {
                      onChange({
                        ...value,
                        has6orMoreAt6and7: false,
                        has5orMoreAtC4OrAbove: false,
                      });
                    }
                  }}
                  mr={2}
                />
                {applicantStrings.qualificationHasMinimumGCSE}
              </Label>
            )}
          </Field>
          <Field name={`${namespace}.has6orMoreAt6and7`} disabled={readOnly} sx={{ my: 8 }}>
            {({ htmlProps }: FieldChildrenProps) => (
              <Label>
                <Checkbox
                  {...htmlProps}
                  checked={value.has6orMoreAt6and7}
                  onChange={checked => {
                    if (checked) {
                      onChange({
                        ...value,
                        has5orMoreAtC4OrAbove: true,
                        has6orMoreAt6and7: true,
                      });
                    } else {
                      onChange({
                        ...value,
                        has6orMoreAt6and7: false,
                      });
                    }
                  }}
                  mr={2}
                />
                {applicantStrings.qualificationHasMinimumGCSEMedical}
              </Label>
            )}
          </Field>
          <Field name={`${namespace}.hasMathsAtB5OrAbove`} disabled={readOnly} sx={{ marginTop: 8, marginBottom: 8 }}>
            {({ htmlProps }: FieldChildrenProps) => (
              <Label>
                <Checkbox
                  {...htmlProps}
                  checked={value.hasMathsAtB5OrAbove}
                  onChange={checked => {
                    onChange({ ...value, hasMathsAtB5OrAbove: checked });
                  }}
                  mr={2}
                />

                {applicantStrings.qualificationsMaths}
              </Label>
            )}
          </Field>
        </>
      ) : null}
    </Flex>
  );
}
