import { checkEmbargo } from "@qmspringboard/portal/src/api";
import { useQuery } from "react-query";
import { EmbargoCheck } from "@qmspringboard/portal/src/model/types.generated";

export const useEmbargo = (): {
  isLoading: boolean;
  until: Date | undefined;
} => {
  const { isLoading, data } = useQuery<{ data: EmbargoCheck }>(["embargo"], () => checkEmbargo(), {
    cacheTime: 60 * 1000,
  });

  if (data?.data && data.data.endTime) {
    return { isLoading: false, until: new Date(Date.parse(data.data.endTime)) };
  } else {
    return { isLoading: isLoading, until: undefined };
  }
};
