import React from "react";
import * as api from "../api";
import { Message, SigninResultCode } from "../model/types";
import { login, loginWarnings, mapMaybeYupValidationToMessages } from "../model/validators";
import { errors, login as loginStrings } from "../strings";
import { Block, Box, Button, Field, FieldError, Flex, Form, Heading, NullableStringInput, FieldChildrenProps } from "../ui";
import { UNKNOWN } from "@qmspringboard/shared/dist/ui/Form";
import { Opt } from "../utils/opt";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

interface FormShape {
  ucasId: Opt<string>;
  emailAddress: Opt<string>;
}

const DEFAULT_LOGIN_ROUTE = "/applicant/applications/new";

const initialState = {
  ucasId: null,
  emailAddress: null,
};

const errorTypes: { [key in SigninResultCode]: Message } = {
  Multiple: {
    level: "error",
    path: [UNKNOWN],
    text: errors.noMatch,
  },
  Different: {
    level: "error",
    path: [UNKNOWN],
    text: errors.noMatch,
  },
  New: { level: "error", path: [UNKNOWN], text: "New Applicant" },
  Unique: { level: "error", path: [UNKNOWN], text: "New Applicant" },
};

const networkError: Message = {
  level: "error",
  path: [UNKNOWN],
  text: errors.network,
};

export default function Login() {
  const navigate = useNavigate();

  const [_next] = useQueryParam("next", StringParam);
  const next = _next ?? DEFAULT_LOGIN_ROUTE;

  const [submitting, setSubmitting] = React.useState(false);

  // if we show warnings on first commit, set this bit to allow them to submit a second time.
  // this should only ever occur if they have a real email address that is 3 edits away
  // from a popular email carrier such as gmail... which seems unlikely.
  const [ignoreWarnings, setIgnoreWarnings] = React.useState(false);
  const [errors, setErrors] = React.useState<Message[]>([]);
  const handleSubmit = async ({ ucasId, emailAddress }: FormShape) => {
    setSubmitting(true);
    setErrors([]);
    try {
      login.parse({ ucasId, emailAddress });
    } catch (e) {
      setErrors(mapMaybeYupValidationToMessages(e));
      setSubmitting(false);
      return;
    }

    if (!ignoreWarnings) {
      // check for warnings and allow submission once they have checked
      try {
        loginWarnings.parse({ emailAddress });
      } catch (e) {
        setErrors(mapMaybeYupValidationToMessages(e, "warning"));
        setIgnoreWarnings(true);
        setSubmitting(false);
        return;
      }
    }
    try {
      const { status, data } = await api.signIn({ ucasId, emailAddress, next });
      if (status >= 200 && status < 300) {
        navigate("/auth/see-email");
      } else if (status >= 400 && status < 500) {
        if (data.signinResult?.code) {
          setErrors([errorTypes[data.signinResult.code]]);
        }
      } else {
        setErrors([networkError]);
      }
    } catch (error) {
      console.error(error);
      setErrors([networkError]);
    }

    setIgnoreWarnings(false);
    setSubmitting(false);
    return;
  };

  return (
    <Flex sx={{ flexWrap: "wrap" }}>
      <Box p={3} bg="grey" sx={{ width: ["100%", 300, 300, 300] }}>
        <Heading variant="heading2" mb={2}>
          {loginStrings.sidebarTitle}
        </Heading>
        {loginStrings.sidebarBody}
      </Box>
      <Box sx={{ flex: "auto" }} mb={2} ml={[0, 2, 2, 2]}>
        <Form<FormShape> initialState={initialState} onSubmit={handleSubmit} messages={errors}>
          {({ setState, state, unhandledMessages }) => (
            <Block isBlocked={submitting} label="Please Wait" containerProps={{ sx: { flexDirection: "column" } }}>
              <Field name="ucasId" label="UCAS ID">
                {({ htmlProps }: FieldChildrenProps) => (
                  <NullableStringInput
                    {...htmlProps}
                    value={state.ucasId}
                    placeholder={loginStrings.ucasIDPlaceholder}
                    onChange={setState("ucasId")}
                  />
                )}
              </Field>
              <Field name="emailAddress" label="E-mail Address">
                {({ htmlProps }: FieldChildrenProps) => (
                  <NullableStringInput
                    {...htmlProps}
                    type="email"
                    value={state.emailAddress}
                    placeholder={loginStrings.emailPlaceholder}
                    onChange={setState("emailAddress")}
                  />
                )}
              </Field>

              <Button as="input" variant="primary" type="submit" value={ignoreWarnings ? loginStrings.buttonTextVerify : loginStrings.buttonText} />
              {unhandledMessages.length > 0
                ? unhandledMessages.map((message, i) => (
                    <FieldError type={message.level} key={i}>
                      {message.text}
                    </FieldError>
                  ))
                : null}
            </Block>
          )}
        </Form>
      </Box>
    </Flex>
  );
}
