import { PortalProgrammeView, PortalRequirementsCheckDTO, PortalSchoolView } from "@qmspringboard/portal/src/model/types.generated";
import { FeeCategory } from "@qmspringboard/shared/src/model/enums.generated";
import React, { useCallback } from "react";
import { chain, keyBy } from "lodash";
import { requirementsCheckResultsIncomplete, requirementsCheckResultsPassed } from "@qmspringboard/shared/src/model/checking";
import { applicationProgramme } from "@qmspringboard/shared/src/strings";
import { Checkbox, Field, Info, ISelectOption, Select } from "@qmspringboard/shared/src/ui";
import { Box, Label, Message } from "@qmspringboard/portal/src/ui";
import ProgrammeQualificationGuidance from "@qmspringboard/portal/src/components/ProgrammeQualificationGuidance";

export const empty = "_EMPTY";

function defaultOption(label: string, options: ISelectOption<string>[]): ISelectOption<string>[] {
  return [{ value: empty, label }, ...options];
}

function mapProgrammesToOptions(
  programmes: PortalProgrammeView[],
  { disabled }: { disabled: boolean } = { disabled: false },
): ISelectOption<string>[] {
  return programmes.map(programme => ({
    value: programme.code,
    label: programme.name,
    disabled,
  }));
}

function mapSchoolsToOptions(schools: PortalSchoolView[]): ISelectOption<string>[] {
  return schools.map(school => ({
    value: school.code,
    label: school.name,
  }));
}

export function ProgrammeAndSchoolSelect({
  programmeCode,
  schoolCode,
  setSchoolCode,
  onChange,
  requirementsCheckResult,
  schools,
  programmes,
}: {
  schools: PortalSchoolView[];
  setSchoolCode: (code: string) => void;
  schoolCode: string;
  programmes: PortalProgrammeView[];
  requirementsCheckResult: PortalRequirementsCheckDTO[];
  programmeCode: string | null;
  onChange: (value: string | null) => void;
  guessedFeeCategory: FeeCategory | null;
}): React.ReactElement {
  const [filter, setFilter] = React.useState(false);

  const handleFilterChange = useCallback(
    (filter: boolean) => {
      setFilter(filter);
      onChange(null);
    },
    [onChange],
  );

  const placesAvailableProgrammeCodes = keyBy(
    requirementsCheckResult.filter(dto => dto.placesAvailable),
    "programmeCode",
  );

  const passedProgrammeCodes = keyBy(
    requirementsCheckResult.filter(dto => requirementsCheckResultsPassed(dto.results)),
    "programmeCode",
  );

  const skippedProgrammeCodes = keyBy(
    requirementsCheckResult.filter(dto => requirementsCheckResultsIncomplete(dto.results)),
    "programmeCode",
  );

  const [openProgrammes, closedProgrammes] = chain(programmes)
    .filter(programme => programme.schoolCode === schoolCode)
    .partition(programme => !!placesAvailableProgrammeCodes[programme.code])
    .value();

  const qualifiedProgrammes = openProgrammes.filter(programme => !!passedProgrammeCodes[programme.code]);

  const undecidedProgrammes = openProgrammes.filter(programme => !!skippedProgrammeCodes[programme.code]);

  const unqualifiedProgrammes = filter
    ? []
    : openProgrammes.filter(
        // filter on codes that don't appear elsewhere
        programme => !passedProgrammeCodes[programme.code] && !skippedProgrammeCodes[programme.code],
      );

  const unqualifiedProgrammeSelected = unqualifiedProgrammes.find(p => p.code === programmeCode);

  const undecidedProgrammeSelected = undecidedProgrammes.find(p => p.code === programmeCode);

  const handleSetSchoolCode = (school: string) => {
    onChange(null);
    setSchoolCode(school);
  };

  const setSelected = (value: string | null) => {
    // if we are qualified, update parent and unset us
    const qualified = [...qualifiedProgrammes, ...undecidedProgrammes].find(p => p.code === value);
    if (qualified) {
      onChange(value);
      return;
    }

    const unqualified = unqualifiedProgrammes.find(p => p.code === value);
    if (unqualified) {
      onChange(value);
    } else {
      onChange(null);
    }
  };

  const options = defaultOption(applicationProgramme.programmePlaceholder, mapProgrammesToOptions(qualifiedProgrammes));

  if (undecidedProgrammes.length > 0) {
    options.push(
      {
        value: "_undecided",
        label: applicationProgramme.undecidedProgrammesBelowHere,
        disabled: true,
      },
      ...mapProgrammesToOptions(undecidedProgrammes),
    );
  }

  if (!filter && unqualifiedProgrammes.length > 0) {
    options.push(
      {
        value: "_unqualified",
        label: applicationProgramme.notQualifiedForProgrammesBelowHere,
        disabled: true,
      },
      ...mapProgrammesToOptions(unqualifiedProgrammes, { disabled: true }),
    );
  }

  if (closedProgrammes.length > 0) {
    options.push(
      {
        value: "_closed",
        label: applicationProgramme.closedProgrammesBelowHere,
        disabled: true,
      },
      ...mapProgrammesToOptions(closedProgrammes, { disabled: true }),
    );
  }

  return (
    <>
      <Field name="school" label="School">
        {({ htmlProps }) => (
          <Select
            {...htmlProps}
            value={schoolCode}
            onChange={handleSetSchoolCode}
            options={defaultOption(applicationProgramme.schoolPlaceholder, mapSchoolsToOptions(schools))}
          />
        )}
      </Field>
      <Field name="programme" label="Programme" disabled={schoolCode === empty}>
        {({ htmlProps }) =>
          schoolCode !== empty && filter && qualifiedProgrammes.length === 0 ? (
            <Message variant="error">{applicationProgramme.doesNotMeetRequirementsForAnyProgrammeAtSchool}</Message>
          ) : (
            <Select {...htmlProps} value={programmeCode} onChange={setSelected} options={options} />
          )
        }
      </Field>
      <Box mb={2}>
        <Label htmlFor="filterProgrammes">
          <Checkbox id="filterProgrammes" checked={filter} onChange={handleFilterChange} mr={2} />
          {applicationProgramme.showQualifiedProgrammes}
        </Label>
      </Box>
      {undecidedProgrammeSelected && (
        <>
          <Info mb={2}>{applicationProgramme.referToAdvisor}</Info>
          <ProgrammeQualificationGuidance requirements={undecidedProgrammeSelected.entryRequirements} />
        </>
      )}
      {unqualifiedProgrammeSelected && (
        <>
          <Message variant="error" mb={2}>
            {applicationProgramme.doesNotMeetRequirementsForProgramme}
          </Message>
          <ProgrammeQualificationGuidance requirements={unqualifiedProgrammeSelected.entryRequirements} />
        </>
      )}
    </>
  );
}
