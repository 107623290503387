import { ibOverallQualification, partitionIBOverall } from "../model/qualifications";
import { EnglishQualification, Qualification, Qualifications, QualificationType, SecondaryEducationQualifications } from "../model/types";
import { checkExhausted } from "../utils";
import { qualificationHasSubject } from "../validators";

export interface AddQualificationAction {
  type: "AddQualification";
  qualification: Qualification;
}

export interface RemoveQualificationAction {
  type: "RemoveQualification";
  index: number;
}

export interface UpdateQualificationAction {
  type: "UpdateQualification";
  index: number;
  qualification: Qualification;
}

export interface UpdateIBOverallPointsAction {
  type: "UpdateIBOverallPoints";
  grade: number | null;
}

export interface UpdateIBOverallYearAction {
  type: "UpdateIBOverallYear";
  yearObtained: number | null;
}

export interface UpdateEnglishQualificationAction {
  type: "UpdateEnglishQualification";
  english: Qualifications["english"];
}

export interface UpdateSecondaryEducationAction {
  type: "UpdateSecondaryEducation";
  secondaryEducation: SecondaryEducationQualifications | null;
}

export interface PrefillQualificationsAction {
  type: "PrefillQualifications";
  qualifications: Qualification[];
}

export type QualificationsAction =
  | AddQualificationAction
  | RemoveQualificationAction
  | UpdateQualificationAction
  | UpdateIBOverallPointsAction
  | UpdateIBOverallYearAction
  | UpdateEnglishQualificationAction
  | UpdateSecondaryEducationAction
  | PrefillQualificationsAction;

function currentYear(): number {
  return new Date().getFullYear();
}

export function addQualificationAction(qual: Qualification | QualificationType | string): QualificationsAction {
  const qualification =
    typeof qual === "string"
      ? {
          tpe: qual,
          subject: null,
          grade: null,
          yearObtained: currentYear(),
        }
      : qual;

  return {
    type: "AddQualification",
    qualification,
  };
}

export function removeQualificationAction(index: number): QualificationsAction {
  return {
    type: "RemoveQualification",
    index,
  };
}

export function updateQualificationAction(index: number, qualification: Qualification): QualificationsAction {
  if (qualificationHasSubject(qualification.tpe)) {
    return {
      type: "UpdateQualification",
      index,
      qualification,
    };
  } else {
    return {
      type: "UpdateQualification",
      index,
      qualification: {
        ...qualification,
        subject: null,
      },
    };
  }
}

export function updateIBOverallPointsAction(grade: number | null): QualificationsAction {
  return {
    type: "UpdateIBOverallPoints",
    grade,
  };
}

export function updateIBOverallYearAction(yearObtained: number | null): QualificationsAction {
  return {
    type: "UpdateIBOverallYear",
    yearObtained,
  };
}

export function updateEnglishQualificationAction(english: Qualifications["english"]): QualificationsAction {
  return {
    type: "UpdateEnglishQualification",
    english,
  };
}

export function updateSecondaryEducationQualificationAction(secondaryEducation: SecondaryEducationQualifications | null): QualificationsAction {
  return {
    type: "UpdateSecondaryEducation",
    secondaryEducation,
  };
}

export function prefillQualificationsAction(qualifications: Qualification[]): QualificationsAction {
  return {
    type: "PrefillQualifications",
    qualifications,
  };
}

// Abstract over Qualifications and PortalQualifications
export interface ProtoQualifications {
  list: Qualification[];
  english?: EnglishQualification | null;
  secondaryEducation?: SecondaryEducationQualifications | null;
}

export default function qualificationsReducer<A extends ProtoQualifications>(state: A, action: QualificationsAction): A {
  switch (action.type) {
    case "AddQualification":
      return {
        ...state,
        list: [...state.list, action.qualification],
      };

    case "RemoveQualification":
      return {
        ...state,
        list: [...state.list.slice(0, action.index), ...state.list.slice(action.index + 1)],
      };

    case "UpdateQualification":
      return {
        ...state,
        list: [...state.list.slice(0, action.index), action.qualification, ...state.list.slice(action.index + 1)],
      };

    case "UpdateIBOverallPoints": {
      const [ibOverall, rest] = partitionIBOverall(state.list);
      const grade = action.grade == null ? null : String(action.grade);

      if (ibOverall == null) {
        if (grade == null) {
          return state;
        } else {
          return {
            ...state,
            list: [...rest, ibOverallQualification(action.grade)],
          };
        }
      } else {
        if (grade == null) {
          return { ...state, list: rest };
        } else {
          return {
            ...state,
            list: [...rest, { ...ibOverall, grade }],
          };
        }
      }
    }

    case "UpdateIBOverallYear": {
      const [ibOverall, rest] = partitionIBOverall(state.list);
      const { yearObtained } = action;

      if (ibOverall == null) {
        return {
          ...state,
          list: [...state.list, ibOverallQualification(null, yearObtained)],
        };
      } else {
        return {
          ...state,
          list: [...rest, { ...ibOverall, yearObtained }],
        };
      }
    }

    case "UpdateEnglishQualification": {
      return {
        ...state,
        english: action.english,
      };
    }

    case "UpdateSecondaryEducation":
      return {
        ...state,
        english:
          // set english to gcse automatically if its not set
          action.secondaryEducation?.type === "Gcse" && state.english === undefined ? { type: "Gcse" } : state.english,
        secondaryEducation: action.secondaryEducation,
      };

    case "PrefillQualifications":
      return {
        ...state,
        list: action.qualifications,
      };

    default:
      return checkExhausted(action);
  }
}
