import React from "react";
import { Box, Field, FieldChildrenProps, Select } from "../ui";
import { SimpleDropdownOptions } from "../model/enums";

interface Props {
  onChange: (value: boolean | null) => void;
  value: boolean | null;
}

const interviewOptions: SimpleDropdownOptions<string | null> = [
  {
    value: null,
    label: "Choose",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

function selectionToValue(value: string | null): boolean | null {
  return value ? value === "Yes" : null;
}

function valueToSelection(value: boolean | null): string | null {
  return value === null ? "Choose" : value ? "Yes" : "No";
}

export function SelectInterview({ onChange, value }: Props): React.ReactElement | null {
  return (
    <Box mb={2}>
      <Field sx={{ flex: 1 }} name="hasBeenInterviewed" label="Have you been interviewed for this programme in the current application year?">
        {({ htmlProps }: FieldChildrenProps) => (
          <>
            <Select {...htmlProps} value={valueToSelection(value)} options={interviewOptions} onChange={v => onChange(selectionToValue(v))} />
            {value && (
              <Box bg="#bd232c" color="#eee" mb={3} p={2}>
                You can only apply once per academic year
              </Box>
            )}
          </>
        )}
      </Field>
    </Box>
  );
}
