import {
  CountryOfDomicileEnum,
  FeeStatusTypeEnum,
  UkImmigrationStatusEnum,
  UkResidencyEnum,
  NationalityEnum,
} from "@qmspringboard/shared/dist/model/enums.generated";
import { guessFeeCategory } from "@qmspringboard/shared/dist/model/feeCategory";
import { unsafeStringToProgrammeCode } from "@qmspringboard/shared/src/model/programmeCode";
import toPath from "lodash/toPath";
import React, { useMemo } from "react";
import * as z from "zod";
import {
  CountryOfDomicile,
  CreateApplicationBody,
  FeeStatus,
  FeeStatusType,
  Message,
  ProgrammeCode,
  SchoolCode,
  UkImmigrationStatus,
  UkResidency,
  Nationality,
} from "../model/types";
import { applicant, applicationProgramme } from "../strings";
import {
  Box,
  Button,
  Checkbox,
  ConfirmObtainedGradesMessage,
  Field,
  FieldChildrenProps,
  FieldError,
  Flex,
  Form,
  Heading,
  Label,
  Select,
  Text,
  ValidationMapping,
} from "../ui";
import { Opt } from "../utils/opt";
import { isUcatCourseCode } from "../utils/ucatHelpers";
import { MessageError } from "./Messages";
import { SelectValidProgramme } from "./SelectValidProgramme";
import { SelectInterview } from "./Interview";

export type ApplicantWithQualificationsData = Omit<CreateApplicationBody, "programmeCode" | "qualifications">;

export const blankApplicantWithQualificationsData: ApplicantWithQualificationsData = {
  countryOfDomicile: null,
};

export interface FormShape {
  gdprAccept: boolean;
  feeStatus: Opt<FeeStatus>;
  countryOfDomicile: Opt<CountryOfDomicile>;
  ukResidency: Opt<UkResidency>;
  ukImmigrationStatus: Opt<UkImmigrationStatus>;
  hasBeenInterviewed: boolean | null;
  nationality: Opt<Nationality>;
}

const initialFormState: FormShape = {
  gdprAccept: false,
  feeStatus: null,
  countryOfDomicile: null,
  ukResidency: null,
  ukImmigrationStatus: null,
  hasBeenInterviewed: null,
  nationality: null,
};

// ApplicantDTO is validated separately, this is just for local stuff that isn't sent to the server
const validateForm = z.object({
  gdprAccept: z.boolean().refine(value => value === true, "You must give us permission to store your information under our data governance policy"),
});

const validateProgrammeCode = z.string().min(1);

const formValidationMapping: ValidationMapping = {};

const mapToMessagesForm = (_applicant: ApplicantWithQualificationsData) => {
  return (error: z.ZodError): Message[] => {
    const errors: Message[] = [];
    error.issues.forEach(stack => {
      const path = stack.path.join(".");
      errors.push({
        level: "error",
        text: stack.message,
        path: formValidationMapping[path] ? formValidationMapping[path] : toPath(stack.path),
      });
    });
    return errors;
  };
};

// only if we are new applicant
const fromFormShape = (body: ApplicantWithQualificationsData, form: FormShape): ApplicantWithQualificationsData => {
  return {
    ...body,
    ukResidency: form.ukResidency ?? null,
    ukImmigrationStatus: form.ukImmigrationStatus ?? null,
    countryOfDomicile: form.countryOfDomicile ?? null,
    nationality: form.nationality ?? null,
  };
};

const toFormShape =
  (body: ApplicantWithQualificationsData) =>
  (form: FormShape): FormShape => {
    const { nationality, countryOfDomicile, ukResidency, ukImmigrationStatus } = body;

    return {
      ...form,
      countryOfDomicile: countryOfDomicile ?? form.countryOfDomicile,
      ukResidency: ukResidency ?? form.ukResidency,
      ukImmigrationStatus: ukImmigrationStatus ?? form.ukImmigrationStatus,
      nationality: nationality ?? form.nationality,
    };
  };

import { appendResidencyLabelWithDateHint } from "@qmspringboard/shared/src/model/residencyDate";

export function validateResidencyStatus(validateResidency: boolean) {
  return validateResidency
    ? z.object({
        countryOfDomicile: z.string(),
        ukResidency: z.string(),
        ukImmigrationStatus: z.string(),
        nationality: z.string({
          invalid_type_error: "You must provide a nationality",
        }),
      })
    : z.object({});
}

export interface IProps {
  body: ApplicantWithQualificationsData;
  existingFeeStatusType: FeeStatusType;
  submitting: boolean;
  onSubmit: (body: ApplicantWithQualificationsData, programmeCode: string) => void;
  messages: Message[];
  defaultSchoolCode?: SchoolCode | null;
  defaultProgramme: ProgrammeCode | null;
}

export default function ApplicantWithQualifications({
  body,
  existingFeeStatusType,
  onSubmit,
  submitting,
  defaultSchoolCode,
  defaultProgramme,
  messages: serverMessages,
}: IProps) {
  const initialState: FormShape = toFormShape(body)(initialFormState);

  const [programmeCode, _setProgrammeCode] = React.useState<ProgrammeCode | null>(defaultProgramme ?? null);

  const [messages, setMessages] = React.useState<Message[]>([]);

  const promptForResidency = existingFeeStatusType != FeeStatusTypeEnum.FeeStatusConfirmed;

  const _onSubmit = React.useMemo(
    () => (values: FormShape) => {
      const newBody = fromFormShape(body, values);
      const validateAndSubmit = async () => {
        try {
          validateResidencyStatus(promptForResidency).parse(newBody);
          validateForm.parse(values);
          validateProgrammeCode.parse(programmeCode);

          if (!programmeCode) {
            // for type checking meh.
            throw new Error("You must choose a programme code");
          }

          onSubmit(newBody, programmeCode);
          return;
        } catch (e) {
          if (e instanceof z.ZodError) {
            // console.log({ newDTO: newBody, e });
            setMessages(mapToMessagesForm(newBody)(e));
            return false;
          } else if (e instanceof MessageError) {
            setMessages([e.data]);
            return false;
          } else if (typeof e === "string") {
            setMessages([
              {
                level: "error",
                path: [],
                text: e,
              },
            ]);
            return false;
          } else {
            throw e;
          }
        }
      };
      validateAndSubmit();
    },
    [body, promptForResidency, programmeCode, onSubmit],
  );

  const countryOptions = useMemo(() => CountryOfDomicileEnum.dropdownOptionsWithBlank(false, "Choose"), []);

  const ukResidencyOptions = useMemo(
    () =>
      UkResidencyEnum.dropdownOptionsWithBlank(false, "Choose").map(option => {
        return {
          ...option,
          label: appendResidencyLabelWithDateHint(option.value, option.label),
        };
      }),
    [],
  );

  const ukImmigrationStatusOptions = useMemo(() => UkImmigrationStatusEnum.dropdownOptionsWithBlank(false, "Choose"), []);

  const nationalityOptions = useMemo(() => NationalityEnum.dropdownOptionsWithBlank(false, "Choose"), []);

  return (
    <Form<FormShape> onSubmit={_onSubmit} initialState={initialState} messages={[...serverMessages, ...messages]}>
      {({ state, setState, unhandledMessages }) => {
        // FIXME: move this content into its own component

        const setProgrammeCode = (_code: string | null) => {
          _setProgrammeCode(_code === null ? null : unsafeStringToProgrammeCode(_code));
        };

        const isUcat = isUcatCourseCode(programmeCode);

        return (
          <>
            <Heading variant="heading1">{applicationProgramme.title}</Heading>
            <Box bg="grey" mb={3} p={2}>
              {applicationProgramme.body}
            </Box>
            <Box bg="grey" mb={3} p={2}>
              <strong>
                If you previously held a firm offer with Queen Mary and are waiting for your Centre Assessed Grades, please do not complete an online
                application.{" "}
              </strong>
              Please email <a href="mailto:clearing@qmul.ac.uk">clearing@qmul.ac.uk</a> with your revised grades and we will get back to you shortly.
            </Box>
            {
              // Once they've entered their qualifications we might display the questions about their residency. But we'll only
              // do that if the server has told us that they don't have a Confirmed fee status on their applicant record already
              promptForResidency && (
                <>
                  <Heading variant="heading1">
                    <Text variant="ref" px={2} mr={2}>
                      1
                    </Text>
                    Your Nationality and Residency
                  </Heading>
                  <Box mb={2}>
                    <Field sx={{ flex: 1 }} name="nationality" label={applicant.nationality}>
                      {({ htmlProps }: FieldChildrenProps) => (
                        <Select {...htmlProps} options={nationalityOptions} value={state.nationality} onChange={setState("nationality")} />
                      )}
                    </Field>
                  </Box>
                  <Flex>
                    <Field sx={{ flex: 1 }} name="countryOfDomicile" label="Country of domicile">
                      {({ htmlProps }: FieldChildrenProps) => (
                        <Select
                          {...htmlProps}
                          options={countryOptions}
                          value={state.countryOfDomicile}
                          onChange={value => {
                            // Clear the programme selection
                            // in case the user has a partially closed programme selected
                            // and this change effectively closes it for them.
                            setProgrammeCode(null);
                            setState("countryOfDomicile")(value);
                          }}
                        />
                      )}
                    </Field>
                  </Flex>
                  <Box mb={2}>
                    <Field sx={{ flex: 1 }} name="residency" label={applicant.residency}>
                      {({ htmlProps }: FieldChildrenProps) => (
                        <Select
                          {...htmlProps}
                          options={ukResidencyOptions}
                          value={state.ukResidency}
                          onChange={value => {
                            // Clear the programme selection
                            // in case the user has a partially closed programme selected
                            // and this change effectively closes it for them.
                            setProgrammeCode(null);
                            setState("ukResidency")(value);
                          }}
                        />
                      )}
                    </Field>
                    <Field sx={{ flex: 1 }} name="immigration" label={applicant.immigration}>
                      {({ htmlProps }: FieldChildrenProps) => (
                        <Select
                          {...htmlProps}
                          options={ukImmigrationStatusOptions}
                          value={state.ukImmigrationStatus}
                          onChange={value => {
                            // Clear the programme selection
                            // in case the user has a partially closed programme selected
                            // and this change effectively closes it for them.
                            setProgrammeCode(null);
                            setState("ukImmigrationStatus")(value);
                          }}
                        />
                      )}
                    </Field>
                  </Box>
                </>
              )
            }
            {((state.ukResidency && state.ukImmigrationStatus) || !promptForResidency) && (
              <>
                <Heading variant="heading1" id="programme-selection">
                  <Text variant="ref" px={2} mr={2}>
                    {promptForResidency ? 2 : 1}
                  </Text>
                  Your Programme
                </Heading>
                <SelectValidProgramme
                  defaultSelectedSchoolCode={defaultSchoolCode || null}
                  programmeCode={programmeCode}
                  onChange={setProgrammeCode}
                  guessedFeeCategory={guessFeeCategory(state.feeStatus, state.ukResidency, state.ukImmigrationStatus)}
                />
              </>
            )}
            {isUcat && <SelectInterview value={state.hasBeenInterviewed} onChange={setState("hasBeenInterviewed")} />}
            {programmeCode ? (
              <>
                <Heading variant="heading1" id="personal-info">
                  Declaration
                </Heading>
                <Box mb={2}>
                  <Label>
                    <Checkbox checked={state.gdprAccept} onChange={setState("gdprAccept")} mr={2} />

                    {applicant.placeholderGDPR}
                  </Label>
                </Box>
                {unhandledMessages.length > 0
                  ? unhandledMessages.map((message, i) => (
                      <FieldError type={message.level} key={i}>
                        {message.text}
                      </FieldError>
                    ))
                  : null}
                <ConfirmObtainedGradesMessage />
                <Button
                  disabled={submitting || (isUcat && (state.hasBeenInterviewed === null || state.hasBeenInterviewed === true))}
                  as={"input"}
                  variant="primary"
                  type="submit"
                  value={applicant.applicantButtonText}
                />
              </>
            ) : null}
          </>
        );
      }}
    </Form>
  );
}
