import React from "react";
import { Flex } from "../ui";
import { NullableIntegerInput } from "./NullableIntegerInput";
import { Opt } from "@qmspringboard/shared/src/utils";
import * as z from "zod";

function isValidDate(day: number, month: number, year: number): boolean {
  const date = new Date(year, month - 1, day);
  const d = date.getDate();
  const m = date.getMonth();
  const y = date.getFullYear();

  return d === day && m === month - 1 && y === year;
}

export const DateComponents = z
  .object({
    day: z.number().nullish(),
    month: z.number().nullish(), // Using 1-indexed values here, i.e. January = 1, not 0
    year: z.number().nullish(),
  })
  .refine(o => o.day && o.month && o.year, "Date of birth is required")
  .refine(o => (o.day && o.month && o.year ? isValidDate(o.day, o.month, o.year) : true), "Date of birth is invalid");

export interface DateComponents extends z.infer<typeof DateComponents> {}

export type NullableDateInputProps = {
  placeholder: [string, string, string];
  name: string;
  value: DateComponents;
  onChange: (newValue: DateComponents) => void;
};

export function NullableDateInput(props: NullableDateInputProps) {
  const { value, onChange, placeholder, name, ...passProps } = props;
  // parse out parts from input date if we aren't null / undefined
  const { year, month, day } = value;

  const handleChange = (index: "day" | "month" | "year") => (fieldValue: Opt<number>) => {
    const newValue: DateComponents = value;
    newValue[index] = fieldValue;
    onChange(newValue);
  };

  return (
    <Flex {...passProps}>
      <NullableIntegerInput
        name={`${name}-day`}
        value={day ? Number(day) : null}
        onChange={handleChange("day")}
        placeholder={placeholder[0]}
        mr={2}
      />
      <NullableIntegerInput
        name={`${name}-month`}
        value={month ? Number(month) : null}
        onChange={handleChange("month")}
        placeholder={placeholder[1]}
        mr={2}
      />
      <NullableIntegerInput name={`${name}-year`} value={year ? Number(year) : null} onChange={handleChange("year")} placeholder={placeholder[2]} />
    </Flex>
  );
}
