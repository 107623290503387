import React from "react";
import { Spinner, Flex } from "./ui";
import ApplicantUploadDocuments from "./components/ApplicantUploadDocuments";
import ApplicantUploadComplete from "./components/ApplicantUploadComplete";
import { STATE, DocumentRequestVerification } from "./Query";
import { NotFound } from "./App";
import { Message } from "@qmspringboard/shared/dist/ui";
import { PageHeader } from "./PageHeader";
import { Route, Routes } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

// wrapper route which only allows contained routes to render if we are authenticated

const Loading = () => (
  <Flex sx={{ alignItems: "center", justifyContent: "center" }}>
    <Spinner size="24px" mr={2} /> Please Wait
  </Flex>
);

export default function DocumentsPage() {
  const [requestId] = useQueryParam("requestId", StringParam);

  if (requestId == null) {
    return <NotFound message="UUID must be provided" />;
  }

  return (
    <DocumentRequestVerification pathVariables={{ requestId }}>
      {pot => {
        switch (pot.state) {
          case STATE.EMPTY:
          case STATE.LOADING: {
            return <Loading />;
          }
          case STATE.ERROR: {
            return <Message variant="error">The file upload request was invalid, has been withdrawn or has expired</Message>;
          }
          case STATE.LOADED: {
            const applicant = pot.data;
            return (
              <>
                <PageHeader applicant={applicant} />
                <Routes>
                  <Route path="add" element={<ApplicantUploadDocuments requestId={requestId} fileType={applicant.attachmentType} />} />
                  <Route path="done" element={<ApplicantUploadComplete />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </>
            );
          }
        }
      }}
    </DocumentRequestVerification>
  );
}
