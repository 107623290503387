import padCharsStart from "lodash/fp/padCharsStart";

const pad5 = padCharsStart("0")(5);

export function formatApplicantId(id: number): string {
  return id < 0 ? "Unknown" : "QM" + pad5(String(id));
}

/** Tool to use in switch statements to ensure we've covered all cases and protect against regressions.
 *
 * Use case:
 *
 * const x: "a" | "b" = // ...
 *
 * switch(x) {
 *   case "a": return // ...
 *   case "b": return // ...
 *   default: return checkExhausted(x); // only compiles if all cases are handled above
 * }
 */
export function checkExhausted(value: never): never {
  throw new Error("Match error: " + value);
}

export function assertNever(value: never): never {
  throw new Error("assertNever failed: " + value);
}

export type Opt<A> = A | null | undefined;

export type IfEquals<T, U, Y = unknown, N = never> = (<G>() => G extends T ? 1 : 2) extends <G>() => G extends U ? 1 : 2 ? Y : N;

export declare const exactType: <T, U>(draft: T & IfEquals<T, U>, expected: U & IfEquals<T, U>) => IfEquals<T, U>;
