import React from "react";
import { Box, Flex, Label } from "theme-ui";
import { FieldError } from "./FieldError";
import { useFieldMessages } from "./Form";
import { Message } from "../model/types";

export interface FieldChildrenProps {
  htmlProps: {
    name: string;
    id?: string;
    "aria-label"?: string;
    "aria-labelledby"?: string;
    disabled?: boolean;
  };
  messages: Message[];
}

export interface IProps {
  id?: string;
  name: string;
  disabled?: boolean;
  label?: string;
  children: (props: FieldChildrenProps) => JSX.Element;
}

export function Field({ label, id, disabled, name, children, ...props }: IProps & React.ComponentProps<typeof Flex>) {
  const messages = useFieldMessages(name);

  const htmlProps = React.useMemo((): FieldChildrenProps["htmlProps"] => {
    const props: FieldChildrenProps["htmlProps"] = {
      name,
      disabled,
    };

    if (label) {
      props["aria-label"] = label;
    }

    if (id) {
      props.id = id;
      props["aria-labelledby"] = id;
    }

    return props;
  }, [id, disabled, label, name]);

  return (
    <Box {...props} sx={props.sx} mb={3}>
      {label && (
        <Label mb={1} htmlFor={name}>
          {label}
        </Label>
      )}
      {children({ htmlProps, messages })}
      {messages && messages.length > 0
        ? messages.map((message, i) => (
            <FieldError key={i} type={message.level} title={message.path.join(".")}>
              {message.text}
            </FieldError>
          ))
        : null}
    </Box>
  );
}
