import { QualificationTypeEnum } from "@qmspringboard/shared/dist/model/enums.generated";
import { Qualification, Subject } from "@qmspringboard/shared/dist/model/qualifications";
import React from "react";
import { qualificationHasSubject } from "@qmspringboard/shared/dist/validators";
import { applicant } from "../strings";
import { Button, Field, NullableStringInput, Overlay, Relative } from "../ui";
import { NullableIntegerInput, Select, ISelectOption } from "../ui";
import { Opt } from "../utils/opt";
import GradeEditor from "./GradeEditor";
import { useSubjects } from "../api";
import { assertNever } from "@qmspringboard/shared/dist/utils";
import { createValidSubjectOptions } from "@qmspringboard/shared/dist/model/subjects";

export interface IProps {
  value: Qualification;
  showYearField: boolean;
  namespace: string;
  onChange: (newValue: Qualification) => void;
}

function subjectsToSelectOptions(subjects: Subject[]): ISelectOption<string | null>[] {
  return [
    { value: null, label: "Subject" },
    { value: "__other", label: "Not Listed Below" },
    { value: "__separator", label: "---", disabled: true },

    ...subjects.map(subject => ({
      label: subject,
      value: subject,
    })),
  ];
}

const Removeable = ({ onCancel, children }: { onCancel: () => void; children: React.ReactNode }) => (
  <Relative>
    <Overlay>
      <Button onClick={onCancel} variant="outlineSecondary">
        X
      </Button>
    </Overlay>
    {children}
  </Relative>
);

const breakpoints = { mb: [2, 0, 0, 0], mr: [0, 2, 2, 2] };

export function QualificationEditor({ value, showYearField, onChange: parentChange, namespace }: IProps): React.ReactElement {
  const subjects = useSubjects();
  const onChange = React.useCallback(
    <K extends keyof Qualification>(field: keyof Qualification) =>
      (v: Qualification[K]) => {
        parentChange({ ...value, [field]: v });
      },
    [parentChange, value],
  );

  const [customQualification, setCustomQualification] = React.useState(!QualificationTypeEnum.isValue(value.tpe));

  const [customSubject, setCustomSubject] = React.useState(false);

  const setLinkedSubject = React.useCallback(
    (value: Opt<string>) => {
      if (value === "__other") {
        setCustomSubject(true);
        onChange("subject")("");
      } else {
        if (value == null) {
          setCustomSubject(false);
        }
        onChange("subject")(value);
      }
    },
    [onChange],
  );

  const showSubject = qualificationHasSubject(value.tpe);

  const subjectField: React.ReactElement | null = React.useMemo(() => {
    if (!showSubject) {
      return null;
    }
    let content!: React.ReactElement;

    switch (subjects.status) {
      case "success": {
        // get list based on qualification
        const allSubjects = createValidSubjectOptions(value.tpe, subjects.data.data);
        const isInDropdown = value.subject ? allSubjects.includes(value.subject) : true;

        content =
          isInDropdown && !customSubject ? (
            <Select name={namespace + ".subject"} value={value.subject} onChange={setLinkedSubject} options={subjectsToSelectOptions(allSubjects)} />
          ) : (
            <Removeable onCancel={() => setLinkedSubject(null)}>
              <NullableStringInput
                name={namespace + ".subject"}
                autoComplete="off"
                value={value.subject}
                onChange={onChange("subject")}
                placeholder={applicant.qualificationSubject}
              />
            </Removeable>
          );
        break;
      }
      case "error": {
        // just show the free text field
        content = (
          <NullableStringInput autoComplete="off" value={value.subject} onChange={onChange("subject")} placeholder={applicant.qualificationSubject} />
        );
        break;
      }
      case "idle":
      case "loading": {
        // loading
        content = (
          <Select
            value={value.subject}
            disabled
            onChange={setLinkedSubject}
            options={[
              {
                label: "Loading Subjects...",
                value: null,
                disabled: true,
              },
            ]}
          />
        );
        break;
      }
      default: {
        assertNever(subjects);
      }
    }

    return (
      <Field name={`${namespace}.subject`} {...breakpoints} sx={{ width: ["100%", 100, 220, 270] }}>
        {() => content}
      </Field>
    );
  }, [customSubject, namespace, onChange, setLinkedSubject, showSubject, subjects, value.subject, value.tpe]);

  const resetQualification = () => {
    setCustomQualification(false);
    onChange("tpe")("A2");
  };

  return (
    <>
      <Field
        name={`${namespace}.tpe`}
        {...breakpoints}
        sx={{
          width: ["100%", 140, 220, 220],
        }}
      >
        {({ htmlProps }) =>
          customQualification ? (
            <Removeable onCancel={resetQualification}>
              <NullableStringInput {...htmlProps} value={value.tpe} onChange={onChange("tpe")} placeholder="Qualification" />
            </Removeable>
          ) : (
            <Select
              {...htmlProps}
              value={value.tpe}
              onChange={value => {
                if (value === "_other") {
                  setCustomQualification(true);
                  onChange("tpe")("");
                } else {
                  onChange("tpe")(value);
                }
              }}
              options={[
                ...QualificationTypeEnum.dropdownOptions(false),
                {
                  label: "Other",
                  value: "_other",
                },
              ]}
            />
          )
        }
      </Field>
      {subjectField}
      <Field
        name={`${namespace}.grade`}
        {...breakpoints}
        sx={{
          width: ["100%", 120, 200, 200],
        }}
      >
        {({ htmlProps }) => (
          <GradeEditor {...htmlProps} value={value.grade} sx={{ minWidth: "120px" }} onChange={onChange("grade")} type={value.tpe} />
        )}
      </Field>
      {showYearField ? (
        <Field
          name={`${namespace}.yearObtained`}
          {...breakpoints}
          sx={{
            width: ["100%", 100, 100, 100],
          }}
        >
          {({ htmlProps }) => (
            <NullableIntegerInput
              {...htmlProps}
              width={100}
              maxLength={4}
              placeholder={applicant.qualificationYearObtained}
              value={value.yearObtained}
              onChange={onChange("yearObtained")}
            />
          )}
        </Field>
      ) : null}
    </>
  );
}
