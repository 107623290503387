import * as React from "react";
import api, { CONFIG } from "./api";
import axios, { AxiosError, AxiosInstance } from "axios";
import { PortalProgrammeView, PortalApplicantView, PortalSchoolView, AttachmentRequestResponseBody } from "./model/types";
import qs from "qs";
import LRU from "lru-cache";

const localCache = new LRU({ max: 50 });
const CancelToken = axios.CancelToken;

const DEFAULT_MODEL: Record<string, unknown> = {};

type ErrorShape = {
  type: string;
  message?: string;
  status?: number;
};

export enum STATE {
  EMPTY,
  LOADING,
  LOADED,
  ERROR,
}

type Pot<EmptyShape, LoadedShape> =
  | { state: STATE.EMPTY; data: EmptyShape }
  | { state: STATE.LOADING; data: EmptyShape; url: string }
  | { state: STATE.LOADED; data: LoadedShape; url: string }
  | { state: STATE.ERROR; data: EmptyShape; error: ErrorShape; url: string };

enum ACTION {
  QUERY_LOAD,
  QUERY_ERROR,
  QUERY_LOADED,
  QUERY_RESET,
}

function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).isAxiosError !== undefined;
}

type Action<EmptyShape, LoadedShape> =
  | { type: ACTION.QUERY_RESET; payload: { data: EmptyShape } }
  | { type: ACTION.QUERY_LOAD; payload: { data: EmptyShape; url: string } }
  | { type: ACTION.QUERY_LOADED; payload: { data: LoadedShape; url: string } }
  | {
      type: ACTION.QUERY_ERROR;
      payload: { error: ErrorShape; data: EmptyShape; url: string };
    };

// FIXME: make this reducer shared across components based on url
function reducer<EmptyShape, LoadedShape>(
  state: Pot<EmptyShape, LoadedShape>,
  action: Action<EmptyShape, LoadedShape>,
): Pot<EmptyShape, LoadedShape> {
  // console.info("<Query>:", ACTION[action.type]);
  switch (action.type) {
    case ACTION.QUERY_RESET: {
      // shortcut
      if (state.state === STATE.EMPTY) {
        return state;
      }
      return { state: STATE.EMPTY, data: action.payload.data };
    }

    case ACTION.QUERY_LOAD: {
      return {
        state: STATE.LOADING,
        data: action.payload.data,
        url: action.payload.url,
      };
    }

    case ACTION.QUERY_LOADED: {
      return {
        state: STATE.LOADED,
        data: action.payload.data,
        url: action.payload.url,
      };
    }

    case ACTION.QUERY_ERROR: {
      return {
        state: STATE.ERROR,
        data: action.payload.data,
        error: action.payload.error,
        url: action.payload.url,
      };
    }

    default: {
      throw new Error("unknown action");
    }
  }
}

type InjectedProps<EmptyShape, DataShape> = Pot<EmptyShape, DataShape>;

type PathVariables = { [key: string]: string | number | boolean };
type QueryVariables = { [key: string]: string | number | boolean };

type PathCreator<VariablesShape> = (variables: VariablesShape) => string;

export interface IProps<EmptyShape, DataShape> {
  query: { path: string | PathCreator<PathVariables>; api?: AxiosInstance };
  //FIXME rename this to something better
  skipLoading?: boolean;
  queryVariables?: QueryVariables;
  pathVariables?: PathVariables;
  defaultDataValue: EmptyShape;
  cache?: boolean;
  children(props: InjectedProps<EmptyShape, DataShape>): JSX.Element | null;
}

//FIXME: generics for pathVariables + queryVariables (breaks typing for some reason at the moment
export default function Query<EmptyShape, DataShape>({
  children,
  skipLoading,
  query,
  queryVariables,
  pathVariables,
  defaultDataValue,
  cache = false,
}: IProps<EmptyShape, DataShape>) {
  const url = React.useMemo(() => {
    const path = typeof query.path === "function" ? query.path(pathVariables || {}) : query.path;
    const target = queryVariables ? `${path}?${qs.stringify(queryVariables)}` : path;
    return target;
  }, [query, queryVariables, pathVariables]);

  const cachedValue = localCache.get(url);

  // depending on whether we are skipping loading, jump straight to the loading state so we don't get
  // re-renders going from empty -> loading. in the future we will need to look at context to see if
  // this resource has been fetched elsewhere
  let initialState: Pot<EmptyShape, DataShape> | null = null;

  if (cachedValue) {
    initialState = {
      state: STATE.LOADED,
      data: cachedValue as DataShape,
      url,
    };
  } else if (skipLoading) {
    initialState = {
      state: STATE.EMPTY,
      data: defaultDataValue,
    };
  } else {
    initialState = {
      state: STATE.LOADING,
      data: defaultDataValue,
      url,
    };
  }

  const [state, dispatch] = React.useReducer<React.Reducer<Pot<EmptyShape, DataShape>, Action<EmptyShape, DataShape>>>(reducer, initialState);

  const loaded = state.state === STATE.LOADED && state.url === url;
  const _api = query.api || api;

  React.useEffect(() => {
    const source = CancelToken.source();

    if (skipLoading === true) {
      dispatch({
        type: ACTION.QUERY_RESET,
        payload: { data: defaultDataValue },
      });
      return;
    }

    const fetch = async () => {
      const cachedData = localCache.get(url);
      if (cachedData) {
        return dispatch({
          type: ACTION.QUERY_LOADED,
          payload: { data: cachedData as DataShape, url },
        });
      }
      dispatch({
        type: ACTION.QUERY_LOAD,
        payload: { data: defaultDataValue, url },
      });

      try {
        const { status, data } = await _api.get<DataShape>(url, {
          cancelToken: source.token,
          validateStatus: function (status) {
            return status < 500;
          },
        });

        if (status >= 200 && status < 300) {
          dispatch({ type: ACTION.QUERY_LOADED, payload: { data, url } });
          if (cache) {
            localCache.set(url, data);
          }
        } else if (status >= 400 && status < 500) {
          // bad request, validation, handle validation
          dispatch({
            type: ACTION.QUERY_ERROR,
            payload: {
              data: defaultDataValue,
              error: { type: "validation", status },
              url,
            },
          });
        } else {
          dispatch({
            type: ACTION.QUERY_ERROR,
            payload: {
              data: defaultDataValue,
              error: { type: "unknown", status },
              url,
            },
          });
        }
      } catch (e) {
        // should only be errors in the range >=500, i.e server or network related.
        // if we were cancelled, ignore
        if (axios.isCancel(e)) {
          return;
        }
        console.error({ e });
        let error = null;
        if (isAxiosError(e)) {
          error = {
            type: "axios unhandled",
            status: e.response?.status,
          };
        } else {
          error = { type: "unknown" };
        }

        dispatch({
          type: ACTION.QUERY_ERROR,
          payload: { data: defaultDataValue, error, url },
        });
      }
    };

    if (!loaded) fetch();

    return () => {
      source && source.cancel("Component unmounted");
    };
  }, [url, cache, loaded, skipLoading, defaultDataValue, dispatch, _api]);

  return children(state);
}

type IPropsW<A, B> = Omit<IProps<A, B>, "query" | "defaultDataValue">;

export const SchoolQuery = (props: IPropsW<[], PortalSchoolView[]>) => {
  return <Query<[], PortalSchoolView[]> query={CONFIG.schools} defaultDataValue={[]} {...props} />;
};

export const ProgrammeQuery = (props: IPropsW<[], PortalProgrammeView[]>) => {
  return <Query<[], PortalProgrammeView[]> query={CONFIG.programmes} defaultDataValue={[]} {...props} />;
};

export const ProgrammeQueryByCode = (props: IPropsW<typeof DEFAULT_MODEL, PortalProgrammeView>) => {
  return <Query<typeof DEFAULT_MODEL, PortalProgrammeView> query={CONFIG.programmeByCode} defaultDataValue={DEFAULT_MODEL} {...props} />;
};

export const ApplicantQuery = (props: IPropsW<typeof DEFAULT_MODEL, PortalApplicantView>) => {
  return <Query<typeof DEFAULT_MODEL, PortalApplicantView> query={CONFIG.applicant} defaultDataValue={DEFAULT_MODEL} {...props} />;
};

export const DocumentRequestVerification = (props: IPropsW<typeof DEFAULT_MODEL, AttachmentRequestResponseBody>) => {
  return (
    <Query<typeof DEFAULT_MODEL, AttachmentRequestResponseBody> query={CONFIG.documentVerification} defaultDataValue={DEFAULT_MODEL} {...props} />
  );
};
