import { AppError } from "@qmspringboard/shared/dist/errors";
export {
  AppError,
  NotFoundError,
  ConcurrentModificationError,
  ValidationError,
  UnauthorizedError,
  UnknownClientError,
  UnknownError,
  UnknownServerError,
} from "@qmspringboard/shared/dist/errors";

export class ForbiddenError extends AppError {
  failedCheck?: unknown;

  constructor(failedCheck: unknown | undefined) {
    super(`Permission denied`);
    this.failedCheck = failedCheck;
  }
}
