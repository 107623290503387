import React from "react";

import { Paragraph, Message as UiMessage } from ".";
import { applicant } from "../strings";

export function ConfirmObtainedGradesMessage() {
  return <Paragraph sx={{ marginBottom: "1em" }}>{applicant.placeholderConfirmedQualifications}</Paragraph>;
}

export function NoPredictedGradesMessage() {
  return (
    <UiMessage
      sx={{
        marginBottom: "1em",
        color: "white",
        backgroundColor: "#0d3273",
      }}
    >
      <Paragraph
        sx={{
          marginBottom: "1em",
        }}
      >
        We are unable to make decisions on clearing applications based on predicted grades. If your qualifications are pending, your application will
        not be considered.
      </Paragraph>
      <Paragraph>
        Please get back in touch once you have received your results. In the meantime, please{" "}
        <a style={{ color: "white" }} href="https://qmul.tfaforms.net/292">
          click here
        </a>{" "}
        to register your interest with Queen Mary.
      </Paragraph>
    </UiMessage>
  );
}
