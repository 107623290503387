import { checkExhausted } from "../utils";
import currentYear from "../utils/currentYear";
import { BtecGrade, IBGrade, QualificationBoard, QualificationTypeEnum } from "./enums.generated";
import { A2Grade, ASGrade, Qualification, QualificationType } from "./qualifications.generated";
export * from "./qualifications.generated";

export function qualificationBoardShortLabel(board: QualificationBoard): string {
  switch (board) {
    case "A2":
      return "A2";
    case "IB":
      return "IB";
    case "BtecRqf":
      return "BTEC RQF";
    case "BtecQcf":
      return "BTEC QCF";
    case "FB":
      return "FR";
    case "GA":
      return "DE";
    case "IHSN":
      return "IN NAT";
    case "IHSWB":
      return "IN WB";
    case "IHSO":
      return "IN OTH";
    case "ID":
      return "IT";
    case "PM":
      return "PL";
    case "SB":
      return "ES";
    case "USD":
      return "US";
    case "UCAT":
      return "UCAT";
    default:
      return checkExhausted(board);
  }
}

export function qualification(
  tpe: QualificationType | string,
  subject: string | null = null,
  grade: string | null = null,
  yearObtained: number | null = currentYear(),
): Qualification {
  return {
    tpe,
    subject,
    grade,
    yearObtained,
  };
}

export function a2Qualification(
  subject: string | null = null,
  grade: A2Grade | null = null,
  yearObtained: number | null = currentYear(),
): Qualification {
  return qualification(QualificationTypeEnum.A2, subject, grade, yearObtained);
}

export function asQualification(
  subject: string | null = null,
  grade: ASGrade | null = null,
  yearObtained: number | null = currentYear(),
): Qualification {
  return qualification(QualificationTypeEnum.A2, subject, grade, yearObtained);
}
export function ibOverallQualification(grade: number | null = null, yearObtained: number | null = currentYear()): Qualification {
  return qualification(QualificationTypeEnum.IBOverall, null, grade == null ? null : String(grade), yearObtained);
}

export function ibHigherQualification(
  subject: string | null = null,
  grade: IBGrade | null = null,
  yearObtained: number | null = currentYear(),
): Qualification {
  return qualification(QualificationTypeEnum.IBHigher, subject, grade, yearObtained);
}

export function btecExtendedQualification(
  subject: string | null = null,
  grades: [BtecGrade, BtecGrade, BtecGrade] | null = null,
  yearObtained: number | null = currentYear(),
): Qualification {
  return qualification(QualificationTypeEnum.BtecExtendedRqf, subject, grades == null ? null : grades.join(""), yearObtained);
}

export function btecNationalQualification(
  subject: string | null = null,
  grades: [BtecGrade, BtecGrade] | null = null,
  yearObtained: number | null = currentYear(),
): Qualification {
  return qualification(QualificationTypeEnum.BtecNationalRqf, subject, grades == null ? null : grades.join(""), yearObtained);
}

export function btecSubsidiaryQualification(
  subject: string | null = null,
  grade: BtecGrade | null = null,
  yearObtained: number | null = currentYear(),
): Qualification {
  return qualification(QualificationTypeEnum.BtecSubsidiaryRqf, subject, grade, yearObtained);
}

export function templateQualifications(board: QualificationBoard | undefined): Qualification[] {
  if (board == null) {
    return [
      {
        tpe: "",
        yearObtained: currentYear(),
      },
    ];
  } else {
    switch (board) {
      case "A2":
        return [
          { tpe: QualificationTypeEnum.A2, yearObtained: currentYear() },
          { tpe: QualificationTypeEnum.A2, yearObtained: currentYear() },
          { tpe: QualificationTypeEnum.A2, yearObtained: currentYear() },
        ];
      case "IB":
        return [
          { tpe: QualificationTypeEnum.IBOverall, yearObtained: currentYear() },
          { tpe: QualificationTypeEnum.IBHigher, yearObtained: currentYear() },
          { tpe: QualificationTypeEnum.IBHigher, yearObtained: currentYear() },
          { tpe: QualificationTypeEnum.IBHigher, yearObtained: currentYear() },
        ];
      case "BtecRqf":
        return [
          {
            tpe: QualificationTypeEnum.BtecExtendedRqf,
            yearObtained: currentYear(),
          },
          { tpe: QualificationTypeEnum.A2, yearObtained: currentYear() },
        ];
      case "BtecQcf":
        return [
          {
            tpe: QualificationTypeEnum.BtecExtendedQcf,
            yearObtained: currentYear(),
          },
          { tpe: QualificationTypeEnum.A2, yearObtained: currentYear() },
        ];
      case "FB":
        return [
          {
            tpe: QualificationTypeEnum.FrenchBacOverall,
            yearObtained: currentYear(),
          },
          { tpe: QualificationTypeEnum.FrenchBac, yearObtained: currentYear() },
          { tpe: QualificationTypeEnum.FrenchBac, yearObtained: currentYear() },
          { tpe: QualificationTypeEnum.FrenchBac, yearObtained: currentYear() },
        ];
      case "GA":
        return [
          {
            tpe: QualificationTypeEnum.GermanAbiturOverall,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.GermanAbitur,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.GermanAbitur,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.GermanAbitur,
            yearObtained: currentYear(),
          },
        ];
      case "IHSN":
        return [
          {
            tpe: QualificationTypeEnum.IndianHsNatOverall,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.IndianHsNat,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.IndianHsNat,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.IndianHsNat,
            yearObtained: currentYear(),
          },
        ];
      case "IHSWB":
        return [
          {
            tpe: QualificationTypeEnum.IndianHsWbOverall,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.IndianHsWb,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.IndianHsWb,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.IndianHsWb,
            yearObtained: currentYear(),
          },
        ];
      case "IHSO":
        return [
          {
            tpe: QualificationTypeEnum.IndianHsOtherOverall,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.IndianHsOther,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.IndianHsOther,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.IndianHsOther,
            yearObtained: currentYear(),
          },
        ];
      case "ID":
        return [
          {
            tpe: QualificationTypeEnum.ItalianDiplomaOverall,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.ItalianDiploma,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.ItalianDiploma,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.ItalianDiploma,
            yearObtained: currentYear(),
          },
        ];
      case "PM":
        return [
          {
            tpe: QualificationTypeEnum.PolishMatura,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.PolishMatura,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.PolishMatura,
            yearObtained: currentYear(),
          },
        ];
      case "SB":
        return [
          {
            tpe: QualificationTypeEnum.SpanishBacOverall,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.SpanishBac,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.SpanishBac,
            yearObtained: currentYear(),
          },
          {
            tpe: QualificationTypeEnum.SpanishBac,
            yearObtained: currentYear(),
          },
        ];
      case "USD":
        return [
          {
            tpe: QualificationTypeEnum.UsDiplomaOverall,
            yearObtained: currentYear(),
          },
          { tpe: QualificationTypeEnum.UsDiploma, yearObtained: currentYear() },
          { tpe: QualificationTypeEnum.UsDiploma, yearObtained: currentYear() },
          { tpe: QualificationTypeEnum.UsDiploma, yearObtained: currentYear() },
        ];
      case "UCAT":
        return [
          { tpe: QualificationTypeEnum.UCATScore, yearObtained: currentYear() },
          { tpe: QualificationTypeEnum.UCATBand, yearObtained: currentYear() },
        ];
      default:
        return checkExhausted(board);
    }
  }
}

export function ibOverallPoints(quals: Qualification[]): number | null {
  const qual = quals.find(qual => qual.tpe === QualificationTypeEnum.IBOverall);
  const points = qual?.grade == null ? null : parseInt(qual.grade, 10);
  return points == null || isNaN(points) ? null : points;
}

export function ibOverallYear(quals: Qualification[]): number | null {
  const qual = quals.find(qual => qual.tpe === QualificationTypeEnum.IBOverall);
  return qual?.yearObtained ?? null;
}

export function partitionIBOverall(quals: Qualification[]): [Qualification | null, Qualification[]] {
  const index = quals.findIndex(qual => qual.tpe === QualificationTypeEnum.IBOverall);

  return index == null ? [null, quals] : [quals[index], [...quals.slice(0, index), ...quals.slice(index + 1)]];
}
