import { Route, Routes } from "react-router-dom";
import Home from "@qmspringboard/portal/src/Home";
import Magic from "@qmspringboard/portal/src/Auth/Magic";
import Login from "@qmspringboard/portal/src/Auth/Login";
import ApplicantPage from "@qmspringboard/portal/src/ApplicantPage";
import DocumentsPage from "@qmspringboard/portal/src/DocumentsPage";
import React from "react";
import { NotFound } from "@qmspringboard/portal/src/App";
import { useEmbargo } from "@qmspringboard/portal/src/hooks/useEmbargo";
import { Embargo } from "@qmspringboard/portal/src/Embargo";
import BadLink from "@qmspringboard/portal/src/BadLink";

export const AppRoutes = () => {
  const { isLoading, until } = useEmbargo();

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (until) {
    return (
      <Routes>
        <Route path="/documents/*" element={<DocumentsPage />} />

        <Route path="*" element={<Embargo until={until} />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/link-expired" element={<BadLink />} />

        <Route path="/auth/see-email" element={<Magic />} />
        <Route path="/auth/sign-in" element={<Login />} />

        <Route path="/applicant/*" element={<ApplicantPage />} />
        <Route path="/documents/*" element={<DocumentsPage />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }
};
