import React from "react";
import { Button } from "@qmspringboard/shared/dist/ui";
import { Link, LinkProps } from "react-router-dom";

export type IProps = React.ComponentProps<typeof Button> & {
  to: LinkProps["to"];
};

export function LinkButton(props: IProps) {
  return <Button {...props} as={Link} />;
}
