import {
  CountryOfDomicileEnum,
  FeeStatusTypeEnum,
  QualificationBoardEnum,
  UkImmigrationStatusEnum,
  UkResidencyEnum,
  NationalityEnum,
} from "@qmspringboard/shared/dist/model/enums.generated";
import { guessFeeCategory } from "@qmspringboard/shared/dist/model/feeCategory";
import { unsafeStringToProgrammeCode } from "@qmspringboard/shared/src/model/programmeCode";
import { templateQualifications } from "@qmspringboard/shared/src/model/qualifications";
import update from "immutability-helper";
import toPath from "lodash/toPath";
import React, { useMemo } from "react";
import * as z from "zod";
import {
  CountryOfDomicile,
  CreateApplicationBody,
  FeeStatus,
  FeeStatusType,
  Message,
  PortalQualifications,
  ProgrammeCode,
  SchoolCode,
  UkImmigrationStatus,
  UkResidency,
  Nationality,
} from "../model/types";
import { applicant } from "../strings";
import {
  Box,
  Button,
  Checkbox,
  Field,
  FieldChildrenProps,
  FieldError,
  Flex,
  Form,
  Heading,
  Label,
  Select,
  Text,
  ValidationMapping,
  NoPredictedGradesMessage,
  ConfirmObtainedGradesMessage,
} from "../ui";
import { Opt } from "../utils/opt";
import {
  ucatQualificationsParser,
  hasAllUCATQualification,
  hasAnyUCATQualification,
  isUcatCourseCode,
  removeEmptyUCATFromQualifications,
} from "../utils/ucatHelpers";
import { MessageError } from "./Messages";
import { QualificationsEditor } from "./QualificationsEditor";
import { SelectValidProgramme } from "./SelectValidProgramme";

export type ApplicantWithoutQualificationsData = Omit<CreateApplicationBody, "programmeCode">;

export const blankApplicantWithoutQualificationsData: ApplicantWithoutQualificationsData = {
  qualifications: {
    list: [],
  },
  countryOfDomicile: null,
};

export interface FormShape {
  qualifications: PortalQualifications;
  surname: Opt<string>;
  forenames: Opt<string>;
  dateOfBirth: Opt<string>;
  telephone: Opt<string>;
  gdprAccept: boolean;
  hasBeenInterviewed: boolean | null;
  feeStatus: Opt<FeeStatus>;
  countryOfDomicile: Opt<CountryOfDomicile>;
  ukResidency: Opt<UkResidency>;
  ukImmigrationStatus: Opt<UkImmigrationStatus>;
  nationality: Opt<Nationality>;
}

const initialFormState: FormShape = {
  qualifications: {
    list: [],
    english: null,
    secondaryEducation: null,
  },
  surname: null,
  forenames: null,
  dateOfBirth: null,
  telephone: null,
  gdprAccept: false,
  hasBeenInterviewed: null,
  feeStatus: null,
  countryOfDomicile: null,
  ukResidency: null,
  ukImmigrationStatus: null,
  nationality: null,
};

// ApplicantDTO is validated separately, this is just for local stuff that isn't sent to the server
const validateForm = z.object({
  gdprAccept: z.boolean().refine(value => value === true, "You must give us permission to store your information under our data governance policy"),
});

const validateProgrammeCode = z.string().min(1);

const formValidationMapping: ValidationMapping = {};

const mapToMessagesForm = (_applicant: ApplicantWithoutQualificationsData) => {
  return (error: z.ZodError): Message[] => {
    const errors: Message[] = [];
    error.issues.forEach(stack => {
      const path = stack.path.join(".");
      errors.push({
        level: "error",
        text: stack.message,
        path: formValidationMapping[path] ? formValidationMapping[path] : toPath(stack.path),
      });
    });
    return errors;
  };
};

// only if we are new applicant
const fromFormShape = (body: ApplicantWithoutQualificationsData, form: FormShape): ApplicantWithoutQualificationsData => {
  return {
    ...body,
    qualifications: {
      ...form.qualifications,
      // @ts-expect-error till we fix types
      version: 1,
    },
    ukResidency: form.ukResidency ?? null,
    ukImmigrationStatus: form.ukImmigrationStatus ?? null,
    countryOfDomicile: form.countryOfDomicile ?? null,
    nationality: form.nationality ?? null,
  };
};

const toFormShape =
  (body: ApplicantWithoutQualificationsData) =>
  (form: FormShape): FormShape => {
    const { qualifications, countryOfDomicile, ukResidency, ukImmigrationStatus, nationality } = body;

    return {
      ...form,
      qualifications: qualifications ?? form.qualifications,
      countryOfDomicile: countryOfDomicile ?? form.countryOfDomicile,
      ukResidency: ukResidency ?? form.ukResidency,
      ukImmigrationStatus: ukImmigrationStatus ?? form.ukImmigrationStatus,
      nationality: nationality ?? form.nationality,
    };
  };

const qualificationsNonEmpty = (quals: PortalQualifications) => {
  return quals.list.length > 0 && quals.secondaryEducation && quals.english !== undefined;
};

import { qualifications as validateQualifications } from "@qmspringboard/shared/dist/validators";
import { appendResidencyLabelWithDateHint } from "@qmspringboard/shared/src/model/residencyDate";
import { SelectInterview } from "./Interview";

export function validateQualificationsAndResidencyStatus(validateResidency: boolean) {
  return validateResidency
    ? z.object({
        qualifications: validateQualifications,
        countryOfDomicile: z.string(),
        ukResidency: z.string(),
        ukImmigrationStatus: z.string(),
        nationality: z.string({
          invalid_type_error: "You must provide a nationality",
        }),
      })
    : z.object({
        qualifications: validateQualifications,
      });
}

export interface IProps {
  body: ApplicantWithoutQualificationsData;
  existingFeeStatusType: FeeStatusType;
  submitting: boolean;
  onSubmit: (body: ApplicantWithoutQualificationsData, programmeCode: string) => void;
  messages: Message[];
  defaultSchoolCode?: SchoolCode | null;
  defaultProgramme: ProgrammeCode | null;
}

export default function ApplicantWithoutQualifications({
  body,
  existingFeeStatusType,
  onSubmit,
  submitting,
  defaultSchoolCode,
  defaultProgramme,
  messages: serverMessages,
}: IProps) {
  const initialState: FormShape = toFormShape(body)(initialFormState);

  const [programmeCode, _setProgrammeCode] = React.useState<ProgrammeCode | null>(defaultProgramme ?? null);

  const [messages, setMessages] = React.useState<Message[]>([]);

  const promptForResidency = existingFeeStatusType != FeeStatusTypeEnum.FeeStatusConfirmed;

  const _onSubmit = React.useMemo(
    () => (values: FormShape) => {
      const newBody = fromFormShape(body, values);
      const validateAndSubmit = async () => {
        try {
          validateQualificationsAndResidencyStatus(promptForResidency).parse(newBody);
          validateForm.parse(values);
          validateProgrammeCode.parse(programmeCode);

          if (!programmeCode) {
            // for type checking meh.
            throw new Error("You must choose a programme code");
          }

          if (isUcatCourseCode(programmeCode)) {
            // special checks to make sure we have a valid UCAT
            ucatQualificationsParser.parse(newBody.qualifications?.list ?? []);
          }

          onSubmit(newBody, programmeCode);
          return;
        } catch (e) {
          if (e instanceof z.ZodError) {
            setMessages(mapToMessagesForm(newBody)(e));
            return false;
          } else if (e instanceof MessageError) {
            setMessages([e.data]);
            return false;
          } else if (typeof e === "string") {
            setMessages([
              {
                level: "error",
                path: [],
                text: e,
              },
            ]);
            return false;
          } else {
            throw e;
          }
        }
      };
      validateAndSubmit();
    },
    [body, promptForResidency, programmeCode, onSubmit],
  );

  const countryOptions = useMemo(() => CountryOfDomicileEnum.dropdownOptionsWithBlank(false, "Choose"), []);

  const ukResidencyOptions = useMemo(
    () =>
      UkResidencyEnum.dropdownOptionsWithBlank(false, "Choose").map(option => {
        return {
          ...option,
          label: appendResidencyLabelWithDateHint(option.value, option.label),
        };
      }),
    [],
  );

  const ukImmigrationStatusOptions = useMemo(() => UkImmigrationStatusEnum.dropdownOptionsWithBlank(false, "Choose"), []);

  const nationalityOptions = useMemo(() => NationalityEnum.dropdownOptionsWithBlank(false, "Choose"), []);

  return (
    <Form<FormShape> onSubmit={_onSubmit} initialState={initialState} messages={[...serverMessages, ...messages]}>
      {({ state, setState, unhandledMessages }) => {
        // FIXME: move this content into its own component

        const setProgrammeCode = (_code: string | null) => {
          const code = _code === null ? null : unsafeStringToProgrammeCode(_code);
          if (isUcatCourseCode(code)) {
            // add UCAT to our qualification list
            if (!hasAnyUCATQualification(state.qualifications.list)) {
              const extra = templateQualifications(QualificationBoardEnum.UCAT);
              const newState = update(state.qualifications, {
                list: {
                  $push: extra,
                },
              });
              setState("qualifications")(newState);
            }
          } else if (isUcatCourseCode(programmeCode)) {
            // if we *were* in a ucat programme, remove it from the qualification list.
            setState("qualifications")(
              update(state.qualifications, {
                list: {
                  $set: removeEmptyUCATFromQualifications(state.qualifications.list),
                },
              }),
            );
          }
          _setProgrammeCode(code);
        };

        const isUcat = isUcatCourseCode(programmeCode);

        return (
          <>
            <Heading variant="heading1" mb={2} id="grade-entry">
              <Text variant="ref" px={2} mr={2}>
                1
              </Text>
              {applicant.gradesHeading}
            </Heading>
            <NoPredictedGradesMessage />
            <Field name="qualifications" mb={2}>
              {() => (
                <Flex sx={{ flexDirection: "column" }}>
                  <QualificationsEditor programmeCode={programmeCode} value={state.qualifications} onChange={setState("qualifications")} />
                </Flex>
              )}
            </Field>
            {
              // Once they've entered their qualifications we might display the questions about their residency. But we'll only
              // do that if the server has told us that they don't have a Confirmed fee status on their applicant record already
              qualificationsNonEmpty(state.qualifications) && promptForResidency && (
                <>
                  <Heading variant="heading1">
                    <Text variant="ref" px={2} mr={2}>
                      2
                    </Text>
                    Your Nationality and Residency
                  </Heading>
                  <Field sx={{ flex: 1 }} name="nationality" label={applicant.nationality}>
                    {({ htmlProps }: FieldChildrenProps) => (
                      <Select {...htmlProps} options={nationalityOptions} value={state.nationality} onChange={setState("nationality")} />
                    )}
                  </Field>
                  <Flex>
                    <Field sx={{ flex: 1 }} name="countryOfDomicile" label="Country of domicile">
                      {({ htmlProps }: FieldChildrenProps) => (
                        <Select
                          {...htmlProps}
                          options={countryOptions}
                          value={state.countryOfDomicile}
                          onChange={value => {
                            // Clear the programme selection
                            // in case the user has a partially closed programme selected
                            // and this change effectively closes it for them.
                            setProgrammeCode(null);
                            setState("countryOfDomicile")(value);
                          }}
                        />
                      )}
                    </Field>
                  </Flex>
                  <Box mb={2}>
                    <Field sx={{ flex: 1 }} name="residency" label={applicant.residency}>
                      {({ htmlProps }: FieldChildrenProps) => (
                        <Select
                          {...htmlProps}
                          options={ukResidencyOptions}
                          value={state.ukResidency}
                          onChange={value => {
                            // Clear the programme selection
                            // in case the user has a partially closed programme selected
                            // and this change effectively closes it for them.
                            setProgrammeCode(null);
                            setState("ukResidency")(value);
                          }}
                        />
                      )}
                    </Field>
                    <Field sx={{ flex: 1 }} name="immigration" label={applicant.immigration}>
                      {({ htmlProps }: FieldChildrenProps) => (
                        <Select
                          {...htmlProps}
                          options={ukImmigrationStatusOptions}
                          value={state.ukImmigrationStatus}
                          onChange={value => {
                            // Clear the programme selection
                            // in case the user has a partially closed programme selected
                            // and this change effectively closes it for them.
                            setProgrammeCode(null);
                            setState("ukImmigrationStatus")(value);
                          }}
                        />
                      )}
                    </Field>
                  </Box>
                </>
              )
            }
            {qualificationsNonEmpty(state.qualifications) && ((state.ukResidency && state.ukImmigrationStatus) || !promptForResidency) && (
              <>
                <Heading variant="heading1" id="programme-selection">
                  <Text variant="ref" px={2} mr={2}>
                    {promptForResidency ? 3 : 2}
                  </Text>
                  Your Programme
                </Heading>
                <SelectValidProgramme
                  qualifications={state.qualifications}
                  defaultSelectedSchoolCode={defaultSchoolCode || null}
                  programmeCode={programmeCode}
                  onChange={setProgrammeCode}
                  guessedFeeCategory={guessFeeCategory(state.feeStatus, state.ukResidency, state.ukImmigrationStatus)}
                />
                {isUcat ? (
                  <>
                    <Box bg="grey" mb={3} p={2}>
                      It looks like you are applying to a medical degree that requires a UCAT qualification. Ensure your UCAT score and band is
                      entered in <a href="#grade-entry">the qualifications section</a>
                    </Box>
                    <SelectInterview value={state.hasBeenInterviewed} onChange={setState("hasBeenInterviewed")} />
                  </>
                ) : null}
              </>
            )}
            {qualificationsNonEmpty(state.qualifications) && programmeCode ? (
              <>
                <Heading variant="heading1" id="personal-info">
                  Declaration
                </Heading>
                <Box mb={2}>
                  <Label>
                    <Checkbox checked={state.gdprAccept} onChange={setState("gdprAccept")} mr={2} />

                    {applicant.placeholderGDPR}
                  </Label>
                </Box>
                {unhandledMessages.length > 0
                  ? unhandledMessages.map((message, i) => (
                      <FieldError type={message.level} key={i}>
                        {message.text}
                      </FieldError>
                    ))
                  : null}
                <ConfirmObtainedGradesMessage />
                <Button
                  disabled={
                    submitting ||
                    (isUcat && !hasAllUCATQualification(state.qualifications.list)) ||
                    (isUcat && (state.hasBeenInterviewed === null || state.hasBeenInterviewed === true))
                  }
                  as={"input"}
                  variant="primary"
                  type="submit"
                  value={applicant.applicantButtonText}
                />
              </>
            ) : null}
          </>
        );
      }}
    </Form>
  );
}
