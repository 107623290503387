import React, { useState } from "react";
import Dropzone, { Accept, FileRejection } from "react-dropzone";
import { errors, upload as uploadStrings } from "../strings";
import styled from "@emotion/styled";
import theme from "../theme";
import { Box, Button, Flex, Spinner, Text } from "../ui";
import { uploadFile } from "../api";
import { FileIcon, ReactFileIconProps } from "react-file-icon";
import { AttachmentType } from "../model/types";
import { Action, groupByStatus, IAction, IState } from "@qmspringboard/shared/dist/reducers/uploads";
import { last } from "lodash";
import { Segment } from "semantic-ui-react";
import { Container } from "@qmspringboard/shared/dist/ui";

// TODO: maybe do progress
// TODO: maybe cancel files that appear to have been uploading for too long

const FileContainer = styled.div({
  position: "relative",
});

const OverlaySpinner = styled.div({
  position: "absolute",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  backgroundColor: "rgba(255,255,255,0.5)",
  margin: "0 auto",
});

const File = ({
  file,
  children,
  busy,
  ...props
}: {
  file: File;
  busy?: boolean;
  children?: React.ReactNode;
} & ReactFileIconProps) => {
  const parts = file.name.split(".");
  const extension = last(parts);
  const type = file.type.split("/")[0];
  return (
    <Flex mb={1}>
      <FileContainer>
        {busy ? (
          <OverlaySpinner>
            <Spinner mt="4px" ml="6px" color="white" size="10px" />
          </OverlaySpinner>
        ) : null}
        <Box sx={{ width: "24px", height: "24px" }}>
          <FileIcon {...props} extension={extension} type={type || "binary"} />
        </Box>
      </FileContainer>
      <Box sx={{ flex: 2, alignSelf: "left", overflow: "clip" }} ml={1}>
        {file.name}
      </Box>
      {children}
    </Flex>
  );
};

const Retry = ({ onClick }: { onClick: (e: React.SyntheticEvent) => void }) => (
  <Button onClick={onClick} variant="outlineErrorSmall" ml={2}>
    Retry
  </Button>
);

const Remove = ({ onClick }: { onClick: (e: React.SyntheticEvent) => void }) => (
  <Button onClick={onClick} variant="outlineSecondarySmall" ml={2} sx={{ height: "2em" }}>
    Remove
  </Button>
);

interface Props {
  doUpload?: boolean;
  // the external value and onChange represent successful uploads
  value: IState;
  type: AttachmentType;
  onChange: React.Dispatch<IAction>;
  accept?: Accept;
  maxSize?: number;
  minSize?: number;
  requestId: string;
}

const UploadDocuments = ({
  value,
  onChange,
  type,
  requestId,
  accept = {
    "image/png": [".png"],
    "image/jpeg": [".jpg", ".jpeg"],
    "application/pdf": [".pdf"],
    "text/plain": [".txt"],
    "application/vnd.ms-excel": [".xls", ".xlsx"],
    "application/vnd.ms-word": [".doc", ".docx"],
  },
  maxSize = 20 * 1024 * 1024,
  minSize = 1,
  doUpload = true,
}: Props) => {
  const statuses = React.useMemo(() => {
    return groupByStatus(value);
  }, [value]);

  const upload = React.useCallback(
    async (files: File[]) =>
      Promise.all(
        Array.from(files).map(file => {
          onChange({ type: Action.UPLOADING, file });
          return uploadFile(file, type, requestId).then(
            token => {
              onChange({ type: Action.COMPLETE, file, token });
            },
            () => {
              onChange({ type: Action.ERROR, file });
            },
          );
        }),
      ),
    [onChange, type, requestId],
  );

  if (statuses.PENDING.length > 0 && statuses.UPLOADING.length < 5) {
    const toUpload = statuses.PENDING.slice(0, 5);
    upload(toUpload);
  }

  React.useEffect(() => {
    if (doUpload) {
      // set NEW to PENDING
      onChange({ type: Action.SET_NEW_TO_PENDING });
    } else {
      // set PENDING to NEW
      onChange({ type: Action.SET_PENDING_TO_NEW });
    }
  }, [onChange, doUpload]);

  const [wrongType, setWrongType] = useState<Array<string>>([]);
  const [tooLarge, setTooLarge] = useState<Array<string>>([]);
  const [tooSmall, setTooSmall] = useState<Array<string>>([]);

  const handleDrop = React.useCallback(
    async (files: File[]) => {
      setWrongType([]);
      setTooLarge([]);
      setTooSmall([]);
      onChange({ type: Action.NEW, files });
      if (doUpload) {
        upload(files);
      }
    },
    [onChange, upload, doUpload],
  );

  function fileTooSmall(reject: FileRejection): boolean {
    // Although DropZone offers a "file-too-small" error code, in practice we see other codes.
    // Example message: {code: 'file-invalid-type', message: 'File is smaller than 1 byte'}
    return reject.errors.some(error => error.code == "file-too-small" || error.message.startsWith("File is smaller than"));
  }

  function fileTooLarge(reject: FileRejection): boolean {
    return reject.errors.some(error => error.code == "file-too-large" || error.message.startsWith("File is larger than"));
  }

  function handleRejections(fileRejections: Array<FileRejection>) {
    const small = fileRejections.filter(fileTooSmall);
    const large = fileRejections.filter(fileTooLarge);

    // Anything else is just "wrong type" as we cannot handle it:
    const wrong = fileRejections.filter(reject => !fileTooLarge(reject) && !fileTooSmall(reject));

    setTooSmall(small.map(rejection => rejection.file.name));
    setTooLarge(large.map(rejection => rejection.file.name));
    setWrongType(wrong.map(rejection => rejection.file.name));
  }

  const remove = (file: File) => onChange({ type: Action.REMOVE, file });
  const retry = (file: File) => handleDrop([file]);

  const acceptedMimeTypes: Array<string> = Object.keys(accept);
  const acceptedExtensions: Array<string> = Object.values(accept).flat();
  const accepted = acceptedMimeTypes.concat(acceptedExtensions).join(",");

  return (
    <>
      <Dropzone onDrop={handleDrop} onDropRejected={handleRejections} maxSize={maxSize} minSize={minSize} accept={accept}>
        {({ getRootProps, getInputProps }) => (
          <Container variant="dropzone">
            <Container
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                p: 2,
              }}
              {...getRootProps()}
            >
              <input accept={accepted} {...getInputProps()} />
              <Box py={3} sx={{ textAlign: "center" }}>
                {uploadStrings.dropZoneText}
              </Box>
              <Flex sx={{ flexDirection: "column" }}>
                {statuses[Action.NEW].length > 0 ? (
                  <Box>
                    {statuses[Action.NEW].map(file => (
                      <File key={file.name} color={theme.colors.primary} file={file}>
                        <Remove
                          onClick={(e: React.SyntheticEvent) => {
                            e.stopPropagation();
                            remove(file);
                          }}
                        />
                      </File>
                    ))}
                  </Box>
                ) : null}
                {statuses[Action.PENDING].length > 0 ? (
                  <Box>
                    {statuses[Action.PENDING].map(file => (
                      <File key={file.name} color={theme.colors.primary} file={file} />
                    ))}
                  </Box>
                ) : null}
                {statuses[Action.UPLOADING].length > 0 ? (
                  <Box>
                    {statuses[Action.UPLOADING].map(file => (
                      <File key={file.name} busy color={theme.colors.primary} file={file} />
                    ))}
                  </Box>
                ) : null}
                {statuses[Action.COMPLETE].length > 0 ? (
                  <Box>
                    {statuses[Action.COMPLETE].map(file => (
                      <File key={file.name} color={theme.colors.tertiary} file={file} />
                    ))}
                  </Box>
                ) : null}
                {statuses[Action.ERROR].length > 0 ? (
                  <Box>
                    <Text mb={2}>{errors.uploading}</Text>
                    {statuses[Action.ERROR].map(file => (
                      <File key={file.name} color={theme.colors.error} file={file}>
                        <>
                          <Retry
                            onClick={(e: React.SyntheticEvent) => {
                              e.stopPropagation();
                              retry(file);
                            }}
                          />
                          <Remove
                            onClick={(e: React.SyntheticEvent) => {
                              e.stopPropagation();
                              remove(file);
                            }}
                          />
                        </>
                      </File>
                    ))}
                  </Box>
                ) : null}
              </Flex>
            </Container>
          </Container>
        )}
      </Dropzone>
      {wrongType.length > 0 && (
        <Segment>
          <span style={{ color: "red" }}>The following files cannot be uploaded as they are of the wrong type:</span>
          <ul>
            {wrongType.map((fileName, index) => {
              return (
                <li style={{ color: "red" }} key={index}>
                  {fileName}
                </li>
              );
            })}
          </ul>
        </Segment>
      )}
      {tooLarge.length > 0 && (
        <Segment>
          <span style={{ color: "red" }}>The following files cannot be uploaded as they are too large:</span>
          <ul>
            {tooLarge.map((fileName, index) => {
              return (
                <li style={{ color: "red" }} key={index}>
                  {fileName}
                </li>
              );
            })}
          </ul>
        </Segment>
      )}
      {tooSmall.length > 0 && (
        <Segment>
          <span style={{ color: "red" }}> The following files cannot be uploaded as they are {minSize == 1 ? "empty" : "too small"}:</span>
          <ul>
            {tooSmall.map((fileName, index) => {
              return (
                <li style={{ color: "red" }} key={index}>
                  {fileName}
                </li>
              );
            })}
          </ul>
        </Segment>
      )}
    </>
  );
};

export default UploadDocuments;
