import React from "react";
import { upload, errors } from "../strings";
import UploadDocuments from "./UploadDocuments";
import { isValid, hasErrors, reducer } from "@qmspringboard/shared/dist/reducers/uploads";
import { AttachmentType } from "../model/types";
import { Box, Flex, Heading, Button, Text } from "../ui";
import { Message } from "@qmspringboard/shared/dist/ui";
import { useNavigate } from "react-router-dom";

interface Props {
  fileType: AttachmentType;
  requestId: string;
}

export default function ApplicantUploadDocuments({ fileType, requestId }: Props) {
  const navigate = useNavigate();

  const [ready, setReady] = React.useState(false);
  const [files, dispatch] = React.useReducer(reducer, {});

  let title: null | string | React.ReactElement = null;
  let body: null | string | React.ReactElement = null;
  let type: AttachmentType = "Other";

  switch (fileType) {
    case "Grades": {
      type = "Grades";
      title = upload.Grades.title;
      body = upload.Grades.body;
      break;
    }
    case "IELTS": {
      type = "IELTS";
      title = upload.IELTS.title;
      body = upload.IELTS.body;
      break;
    }
    default:
      type = "Other";
      title = upload.Other.title;
      body = upload.Other.body;
  }

  // if we only have errored files, set ready to false.

  const hasFiles = Object.keys(files).length > 0;

  const onSubmit = () => {
    setReady(true);
  };

  // send and uploaded
  if (ready && isValid(files) && hasFiles) {
    navigate && navigate(`../done?requestId=${requestId}`);
  }

  const showError = hasErrors(files);

  return (
    <Flex sx={{ flexDirection: "column" }} mt={2}>
      <Heading variant="heading1" mb={2}>
        {title}
      </Heading>
      <Text mb={2}>{body}</Text>
      {showError && (
        <Message variant="error" mb={2}>
          {errors.uploading}
        </Message>
      )}
      <Box mb={2}>
        <UploadDocuments doUpload={ready} requestId={requestId} value={files} type={type} onChange={dispatch} />
      </Box>
      <Box mb={2}>
        <Button disabled={!hasFiles || ready} variant="primary" onClick={onSubmit}>
          Send Files
        </Button>
      </Box>
    </Flex>
  );
}
