import * as React from "react";
import isEqual from "lodash/isEqual";
// import debounce from "lodash/debounce";
import { Path, Message } from "../model/types";
import { pathToString, stringToPath } from "../validators";
import { useDebouncedCallback } from "use-debounce";
import { Box } from "theme-ui";

const FormMessagesContext = React.createContext<Message[]>([]);
const HandledMessagesContext = React.createContext<(path: string) => void>(() => undefined);

const messagesForPath =
  (path: Path) =>
  (value: Message[]): Message[] => {
    return value.filter(message => isEqual(message.path, path));
  };

export const UNKNOWN = "__UNKNOWN__";
export type ValidationMapping = { [key: string]: Path };

type HandledMessages = { [path: string]: boolean };
function mapPathChainOrUnknown(handled: HandledMessages) {
  return (path: Path): Path => {
    let up = 0;
    // go further up tree till we find match
    while (up < path.length) {
      const pathPart = pathToString(path.slice(0, path.length - up));
      up++;
      if (handled[pathPart]) {
        return stringToPath(pathPart);
      }
    }
    return [UNKNOWN];
  };
}

function mapUnregisteredFieldsToUnknown(handledMessages: HandledMessages) {
  // look through registered fields, move errors up the stack till they match or set as unknown
  return (messages: Message[]): Message[] =>
    messages.map(message => ({
      ...message,
      path: mapPathChainOrUnknown(handledMessages)(message.path),
    }));
}

const getUnknownMessages = messagesForPath([UNKNOWN]);

export function useFieldMessages(path: string) {
  const myMessages = React.useMemo(() => messagesForPath(stringToPath(path)), [path]);
  const handledMessages = React.useContext(HandledMessagesContext);
  const messages = React.useContext(FormMessagesContext);

  React.useEffect(() => {
    handledMessages(path);
  }, [handledMessages, path]);

  return React.useMemo(() => myMessages(messages), [messages, myMessages]);
}

type InjectedProps<FormShape> = {
  unhandledMessages: Message[];
  state: FormShape;
  setState: <K extends keyof FormShape>(formField: keyof FormShape) => (value: FormShape[K]) => void;
};

export type IProps<FormShape> = {
  id?: string;
  initialState: FormShape;
  messages: Message[];
  onSubmit: (values: FormShape) => void | Promise<void>; // for async functions
  children(props: InjectedProps<FormShape>): JSX.Element;
};

export function Form<FormShape>({ children, initialState, onSubmit, messages = [], ...props }: IProps<FormShape>) {
  const [formState, setFormState] = React.useState<FormShape>(initialState);

  const handleSubmit: React.FormEventHandler<HTMLElement> = e => {
    e.preventDefault();
    onSubmit(formState);
  };

  const setState = React.useMemo(
    (): InjectedProps<FormShape>["setState"] => formField => value => {
      setFormState({
        ...formState,
        [formField]: value,
      });
    },
    [formState, setFormState],
  );

  return (
    <Box as="form" onSubmit={handleSubmit} {...props}>
      <MessageHandling messages={messages}>{({ unhandledMessages }) => children({ setState, state: formState, unhandledMessages })}</MessageHandling>
    </Box>
  );
}

export function MessageHandling({ messages, children }: { messages: Message[]; children: (props: { unhandledMessages: Message[] }) => JSX.Element }) {
  const internalHandled = React.useRef<HandledMessages>({});
  const [handledMessages, setHandledMessages] = React.useState<HandledMessages>({});

  const debouncedSetHandled = useDebouncedCallback(setHandledMessages, 1);

  const setHandled = React.useCallback(
    (path: string) => {
      if (!internalHandled.current[path]) {
        internalHandled.current[path] = true;
        debouncedSetHandled({
          ...internalHandled.current,
          [path]: true,
        });
      }
    },
    [internalHandled, debouncedSetHandled],
  );

  // gets messages with their computed path (exact, decendant or unknown)
  const mappedMessages = React.useMemo(() => {
    return mapUnregisteredFieldsToUnknown(handledMessages)(messages);
  }, [handledMessages, messages]);

  // find any messages which match the unknown path.
  const unhandledMessages = React.useMemo(() => getUnknownMessages(mappedMessages), [mappedMessages]);

  return (
    <FormMessagesContext.Provider value={mappedMessages}>
      <HandledMessagesContext.Provider value={setHandled}>{children({ unhandledMessages })}</HandledMessagesContext.Provider>
    </FormMessagesContext.Provider>
  );
}
