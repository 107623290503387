import type { Subjects } from "@qmspringboard/shared/dist/model/subjects";
import axios, { AxiosError, AxiosInstance } from "axios";
import qs from "qs";
import {
  CheckEntryRequirementsRequestBody,
  CreateApplicationBody,
  EmbargoCheck,
  FeeCategory,
  PortalProgrammeView,
  PortalQualifications,
  PortalRequirementsCheckDTO,
  PortalSchoolView,
} from "./model/types.generated";

import { useQuery } from "react-query";

import {
  AppError,
  ConcurrentModificationError,
  ForbiddenError,
  NotFoundError,
  UnauthorizedError,
  UnknownClientError,
  UnknownError,
  UnknownServerError,
  ValidationError,
} from "./error";
import { AttachmentType, Message, PortalApplicantDTO, PortalAuthentication, SigninResultCode, UploadLink } from "./model/types";
import { Opt } from "./utils/opt";

export interface AuthenticationInformation {
  ucasId: Opt<string>;
  emailAddress: Opt<string>;
  next: Opt<string>;
}
export interface FileInformation {
  filename: string;
  filetype: string;
}

type VariablesType = { [key: string]: string | number | boolean };
type PathCreator = (variables: VariablesType) => string;
type ConfigShape = {
  [key: string]: { path: string | PathCreator; api?: AxiosInstance };
};

const baseURL = "/portal";
export const api = axios.create({
  baseURL,
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem("bearerToken");
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).isAxiosError !== undefined;
}

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (isAxiosError(error)) {
      return Promise.reject(
        handleErrors({
          status: error.response?.status,
          json: error.response?.data,
        }),
      );
    } else {
      return Promise.reject("unknown");
    }
  },
);

export default api;

export const unauthenticatedAPI = axios.create({
  baseURL,
});

export const CONFIG: ConfigShape = {
  schools: { path: "/api/schools" },
  programmes: { path: "/api/programmes" },
  programmeByCode: {
    path: ({ code }) => `/api/programmes/${code}`,
  },
  applicant: { path: "/api/applicant" },
  createApplication: { path: "/api/application" },
  createApplicantAndApplication: { path: "/api/applicant" },
  checkEntryRequirements: {
    path: "/api/qualifications/entry",
  },
  documentVerification: {
    path: ({ requestId }) => `/api/attachment-request/${requestId}`,
    api: unauthenticatedAPI,
  },
};
interface SigninResult {
  signinResult?: {
    code: SigninResultCode;
  };
}

export function checkEmbargo() {
  return unauthenticatedAPI.get<EmbargoCheck>("/api/check-embargo");
}

export function signIn(data: AuthenticationInformation) {
  return unauthenticatedAPI.post<SigninResult>("/auth/signin", qs.stringify(data), {
    withCredentials: true,
    validateStatus: function (status) {
      return status < 500;
    },
  });
}

export function createApplication(body: CreateApplicationBody) {
  return api.post(`${CONFIG.createApplication.path}`, body);
}

export function createApplicantAndApplication({ programmeCode, data }: { programmeCode: string; data: PortalApplicantDTO["applicant"] }) {
  return api.post(`${CONFIG.createApplicantAndApplication.path}?programmeCode=${programmeCode}`, data);
}

export function checkEntryRequirements(data: CheckEntryRequirementsRequestBody): Promise<PortalRequirementsCheckDTO> {
  return api.post(`${CONFIG.checkEntryRequirements.path}`, data);
}

export function getBearerToken() {
  return unauthenticatedAPI.get<PortalAuthentication>("/auth/bearer-token");
}

export async function commitApplicantUpload(token: string, data: { [key: string]: string }) {
  const result = await api.post<UploadLink>(`/api/upload-complete/${token}`, data);
  return result;
}

export async function uploadFile(file: File, type: AttachmentType, requestId: string): Promise<string> {
  const result = await unauthenticatedAPI.get<UploadLink>(`/api/attachment-request/${requestId}/upload-link?fileName=${file.name}`);
  // console.info({ result });

  const options = {
    headers: {
      "Content-Type": file.type,
    },
  };

  await unauthenticatedAPI.put(result.data.url, file, options);
  // console.info({ uploadStatus });
  return result.data.url;
}

export function useSchools(code?: string) {
  return useQuery<{ data: PortalSchoolView[] }, Error>(["schools", code], async () => {
    const result = await api.get<PortalSchoolView[]>(`/api/schools${code ? `/${code}` : ""}`);
    return result;
  });
}

export function useProgrammes(code?: string) {
  return useQuery<{ data: PortalProgrammeView[] }, Error>(["programmes", code], async () => {
    const result = await api.get<PortalProgrammeView[]>(`/api/programmes${code ? `/${code}` : ""}`);
    return result;
  });
}

export function useEntryQualifications(qualifications?: PortalQualifications, feeCategory?: FeeCategory | null) {
  return useQuery<{ data: PortalRequirementsCheckDTO[] }, Error>(
    ["entry-requirements", feeCategory, qualifications],
    async () => {
      const result = await api.post<PortalQualifications, { data: PortalRequirementsCheckDTO[] }>(`/api/qualifications/entry`, {
        version: 1,
        qualifications,
        feeCategory,
      });
      return result;
    },
    {
      enabled: qualifications == null || qualifications.list.length > 0,
    },
  );
}

export const useSubjects = () => {
  return useQuery<{ data: Subjects }>("subjects", () => api.get<Subjects>(`/api/subjects`));
};

function toMessage(message: string): Message[] {
  return [
    {
      level: "error",
      path: [],
      text: message,
    },
  ];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleErrors({ status, json }: { status?: number; json: any }): any {
  const parsedJson = JSON.parse(json?.message || {});
  function logged(error: AppError) {
    console.error(error);
    return error;
  }
  if (!status) {
    throw logged(new UnknownError(json == null ? `Unknown error` : json.message || `Unknown error: ${JSON.stringify(json)}`));
  }

  if (status >= 200 && status < 300) {
    return json;
  } else if (status === 401) {
    throw logged(new UnauthorizedError(json == null ? `Unauthorized` : json.message || `Unauthorized: ${JSON.stringify(json)}`));
  } else if (status === 403) {
    throw logged(new ForbiddenError(json?.failedCheck ?? undefined));
  } else if (status === 404) {
    throw logged(new NotFoundError(json && json.itemType ? json.itemType : "unknown", json && json.item ? json.item : "unknown"));
  } else if (status === 409) {
    throw logged(new ConcurrentModificationError(json && json.itemType ? json.itemType : "unknown", json && json.item ? json.item : "unknown"));
  } else if (status === 422 && parsedJson.messages) {
    throw logged(new ValidationError(parsedJson.messages));
  } else if (status === 400 && parsedJson.messages) {
    throw logged(new ValidationError(parsedJson.messages));
  } else if (status >= 400 && status < 500) {
    throw logged(new UnknownClientError(json == null ? `Unknown client error` : json.message || `Unknown client error: ${JSON.stringify(json)}`));
  } else if (status >= 500 || status === 0) {
    throw logged(
      new UnknownServerError(
        json === null
          ? `Could not contact the Springboard servers. Check your connection and try again!`
          : json.message || `Unknown network or server error: ${JSON.stringify(json)}`,
      ),
    );
  } else {
    throw logged(new UnknownError(json == null ? `Unknown error` : json.message || `Unknown error: ${JSON.stringify(json)}`));
  }
}

const empty: Message[] = [];
export function createMessagesFromError(error: unknown): Message[] {
  if (error === undefined || error === null) {
    return empty;
  }
  if (error instanceof AppError) {
    if (error instanceof NotFoundError) {
      return toMessage(error.allMessages());
    } else if (error instanceof ValidationError) {
      return error.messages;
    } else if (error instanceof UnauthorizedError) {
      return toMessage(error.allMessages());
    } else if (error instanceof ForbiddenError) {
      return toMessage(error.allMessages());
    } else if (error instanceof ConcurrentModificationError) {
      return toMessage(error.allMessages());
    } else if (error instanceof UnknownClientError) {
      return toMessage(error.allMessages());
    } else if (error instanceof UnknownServerError) {
      return toMessage(error.allMessages());
    } else if (error instanceof UnknownError) {
      return toMessage(error.allMessages());
    }
  }
  return toMessage("Unknown issue");
}
