import { checkExhausted } from "../utils";
import { RequirementsCheckComplete, RequirementsCheckIncomplete, RequirementsCheckItem, RequirementsCheckResults } from "./requirements.generated";

export function requirementsCheckResultsFold<A>(
  results: RequirementsCheckResults,
  whenIncomplete: (results: RequirementsCheckIncomplete) => A,
  whenComplete: (results: RequirementsCheckComplete) => A,
): A {
  switch (results.type) {
    case "RequirementsCheckIncomplete":
      return whenIncomplete(results);

    case "RequirementsCheckComplete":
      return whenComplete(results);

    default:
      return checkExhausted(results);
  }
}

export function requirementsCheckResultsItems(results: RequirementsCheckResults): RequirementsCheckItem[] {
  return requirementsCheckResultsFold(
    results,
    _ => [],
    res => [...res.feResults, ...res.auxResults],
  );
}

export function requirementsCheckResultsPassed(results: RequirementsCheckResults): boolean {
  return requirementsCheckResultsFold(
    results,
    _ => false,
    res => res.feResults.every(r => r.status === "Passed") && res.auxResults.every(r => r.status === "Passed"),
  );
}

export function requirementsCheckResultsFailed(results: RequirementsCheckResults): boolean {
  return requirementsCheckResultsFold(
    results,
    _ => false,
    res => res.feResults.some(r => r.status === "Failed") || res.auxResults.some(r => r.status === "Failed"),
  );
}

export function requirementsCheckResultsIncomplete(results: RequirementsCheckResults): boolean {
  return requirementsCheckResultsFold(
    results,
    _ => true,
    _ => false,
  );
}
