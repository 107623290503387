import { Box } from "@qmspringboard/shared/dist/ui";
import { unsafeStringToProgrammeCode } from "@qmspringboard/shared/src/model/programmeCode";
import React, { PropsWithChildren } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { QueryParamConfig, decodeString, encodeString, useQueryParam } from "use-query-params";
import { createApplicantAndApplication, createApplication, createMessagesFromError } from "../api";
import { PortalApplicant as ApplicantType, FeeStatusType, Tagged, unsafeTag } from "../model/types";
import Applicant from "./Applicant";
import ApplicantWithQualifications, { ApplicantWithQualificationsData, blankApplicantWithQualificationsData } from "./ApplicantWithQualifications";
import ApplicantWithoutQualifications, {
  ApplicantWithoutQualificationsData,
  blankApplicantWithoutQualificationsData,
} from "./ApplicantWithoutQualifications";

interface Props {
  className?: string;
  hasApplicantRecord: boolean;
  hasQualifications: boolean;
  feeStatusType: FeeStatusType;
}

const blankApplicant: ApplicantType = {
  details: {
    ucasPersonalId: null,
    studentId: null,
    surname: null,
    forenames: null,
    dateOfBirth: null,
    email: null,
    sitsHomeEmail: null,
    telephone1: null,
    telephone2: null,
    version: 1,
  },
  //This is actually overridden by the api but it needs to exist.
  qualifications: {
    list: [],
  },
};

function taggedQueryParam<T extends string>(): QueryParamConfig<Tagged<T> | null> {
  return {
    encode: code => encodeString(code),
    decode: param => {
      const opt = decodeString(param);
      return opt == null ? null : unsafeTag(opt);
    },
  };
}

export default function ApplicationNew({ hasApplicantRecord, hasQualifications, feeStatusType }: Props) {
  const navigate = useNavigate();

  const [schoolCode] = useQueryParam("schoolCode", taggedQueryParam<"SchoolCode">());

  const [programmeCode] = useQueryParam("programmeCode", taggedQueryParam<"ProgrammeCode">());

  const onSuccess = () => {
    if (navigate) navigate("/applicant/applications/complete");
  };

  const {
    mutate: mutateApplicantAndApplication,
    isLoading: mutateApplicantAndApplicationLoading,
    error: mutateApplicantAndApplicationError,
  } = useMutation(createApplicantAndApplication, { onSuccess: onSuccess });

  const handleApplicantSubmit = (data: ApplicantType, programmeCode: string) => {
    mutateApplicantAndApplication({
      programmeCode,
      data,
    });
  };

  const {
    mutate: mutateApplicantWithoutQualificationsData,
    isLoading: mutateApplicantWithoutQualificationsDataLoading,
    error: mutateApplicantWithoutQualificationsDataError,
  } = useMutation(createApplication, { onSuccess: onSuccess });

  const handleApplicantWithoutQualificationsDataSubmit = (data: ApplicantWithoutQualificationsData, programmeCode: string) => {
    mutateApplicantWithoutQualificationsData({
      ...data,
      programmeCode: unsafeStringToProgrammeCode(programmeCode),
    });
  };

  const {
    mutate: mutateApplicantWithQualificationsData,
    isLoading: mutateApplicantWithQualificationsDataLoading,
    error: mutateApplicantWithQualificationsDataError,
  } = useMutation(createApplication, { onSuccess: onSuccess });

  const handleApplicantWithQualificationsDataSubmit = (data: ApplicantWithQualificationsData, programmeCode: string) => {
    mutateApplicantWithQualificationsData({
      ...data,
      programmeCode: unsafeStringToProgrammeCode(programmeCode),
    });
  };

  const submitting =
    mutateApplicantAndApplicationLoading || mutateApplicantWithoutQualificationsDataLoading || mutateApplicantWithQualificationsDataLoading;

  return !hasApplicantRecord ? (
    // No existing record for this applicant on Springboard, so gather all the details
    <SpaceBelow>
      <Applicant
        onSubmit={handleApplicantSubmit}
        applicantDTO={blankApplicant}
        defaultSchoolCode={schoolCode}
        defaultProgramme={programmeCode}
        submitting={submitting}
        messages={createMessagesFromError(mutateApplicantAndApplicationError)}
      />
    </SpaceBelow>
  ) : !hasQualifications ? (
    // There is an existing applicant on Springboard, but without any qualifications (most likely because it's a SITS import).
    // So gather qualification, and maybe fee status, details
    <SpaceBelow>
      <ApplicantWithoutQualifications
        onSubmit={handleApplicantWithoutQualificationsDataSubmit}
        body={blankApplicantWithoutQualificationsData}
        existingFeeStatusType={feeStatusType}
        defaultSchoolCode={schoolCode}
        defaultProgramme={programmeCode}
        submitting={submitting}
        messages={createMessagesFromError(mutateApplicantWithoutQualificationsDataError)}
      />
    </SpaceBelow>
  ) : (
    // Applicant exists already on Springboard, and they have qualifications recorded, so just allow them to pick a programme to apply for
    <SpaceBelow>
      <ApplicantWithQualifications
        onSubmit={handleApplicantWithQualificationsDataSubmit}
        body={blankApplicantWithQualificationsData}
        existingFeeStatusType={feeStatusType}
        defaultSchoolCode={schoolCode}
        defaultProgramme={programmeCode}
        submitting={submitting}
        messages={createMessagesFromError(mutateApplicantWithQualificationsDataError)}
      />
    </SpaceBelow>
  );
}

function SpaceBelow({ children }: PropsWithChildren<unknown>) {
  return (
    <>
      {children}
      <Box mt={`300px`} />
    </>
  );
}
