import React from "react";
import { Input } from "theme-ui";

export type IValue = number | null | undefined;

type Override<T, U> = Omit<T, keyof U> & U;
type InputProps = React.ComponentProps<typeof Input>;

type LocalProps = {
  value: IValue;
  onChange: (value: IValue) => void;
};

export type IProps = Override<InputProps, LocalProps>;

export const NullableIntegerInput = ({ onChange: parentChange, value, ...props }: IProps) => {
  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    if (val === "") {
      parentChange(null);
    } else {
      const castToNumber = Number(val);
      if (!Number.isNaN(castToNumber)) {
        parentChange(castToNumber);
      }
    }
  };
  return <Input onChange={onChange} value={value === null ? "" : value} pattern="[0-9]*" inputMode="numeric" {...props} />;
};
