import React from "react";
import { Box, Flex, Heading, Text } from "../ui";
import { applicationComplete } from "../strings";
import { formatApplicantId } from "@qmspringboard/shared/dist/utils";

interface Props {
  applicantId: number;
}

export default function ApplicationComplete({ applicantId }: Props) {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Flex sx={{ flexDirection: "column" }}>
        <Heading variant="heading1" mb={2}>
          {applicationComplete.title}
        </Heading>
        <Heading variant="heading2" mb={4}>
          {applicationComplete.applicationNumberPrefix}
          <Text variant="ref" ml={1} px={2} py={1}>
            {formatApplicantId(applicantId)}
          </Text>
        </Heading>
      </Flex>
      <Box>
        <Text>{applicationComplete.body}</Text>

        <Heading variant="heading2" mb={2} mt={2}>
          {applicationComplete.sidebarTitle}
        </Heading>
        <Text>{applicationComplete.sidebarBody}</Text>
      </Box>
    </Flex>
  );
}
