import * as React from "react";
import { Flex, Box, Button, ButtonProps, ThemeUIStyleObject, BoxProps } from "theme-ui";
import { Menu } from "@headlessui/react";
import { DownArrow } from "./icons/DownArrow";

type Props = { items: React.ReactNode } & ButtonProps;

const itemsSx: ThemeUIStyleObject = {
  right: 0,
  flexDirection: "column",
  position: "absolute",
  top: "100%",
};

const itemSx: ThemeUIStyleObject = {
  variant: "buttonItems.primary",
};

const MenuItemContainer = ({ children }: React.PropsWithChildren<Record<string, unknown>>) => (
  <Menu.Items as={Flex} sx={itemsSx}>
    {children}
  </Menu.Items>
);

// DropdownButton.MenuItemContainer.displayName = "test";

const MenuItem = ({
  children,
  ...props
}: {
  children: React.ReactNode | ((props: { active: boolean }) => React.ReactNode);
} & BoxProps) => (
  <Menu.Item as={Box} {...props} sx={itemSx}>
    {children}
  </Menu.Item>
);

export function DropdownButton({ children, items, sx, ...buttonProps }: Props) {
  const childrenWithIcon = (
    <Flex sx={{ alignContent: "center" }}>
      <Box sx={{ flex: "auto" }}>{children}</Box>
      <DownArrow size={24} />
    </Flex>
  );

  return (
    <Menu as={Box} sx={{ position: "relative", display: "flex", ...sx }}>
      <Menu.Button as={React.Fragment}>
        <Button {...buttonProps} sx={{ flex: "auto" }}>
          {childrenWithIcon}
        </Button>
      </Menu.Button>
      {items}
    </Menu>
  );
}

DropdownButton.MenuItem = MenuItem;
DropdownButton.MenuItemContainer = MenuItemContainer;
