import {
  CountryOfDomicileEnum,
  NationalityEnum,
  QualificationBoardEnum,
  UkImmigrationStatusEnum,
  UkResidencyEnum,
} from "@qmspringboard/shared/dist/model/enums.generated";
import { guessFeeCategory } from "@qmspringboard/shared/dist/model/feeCategory";
import { formatAgeConcernPortal, isOldEnoughToApplyToCourse } from "@qmspringboard/shared/dist/utils/age";
import { appendResidencyLabelWithDateHint } from "@qmspringboard/shared/dist/model/residencyDate";
import toPath from "lodash/toPath";
import React, { useMemo } from "react";
import * as z from "zod";
import {
  CountryOfDomicile,
  FeeStatus,
  Message,
  Nationality,
  PortalApplicant as ApplicantType,
  PortalQualifications,
  ProgrammeCode,
  SchoolCode,
  UkImmigrationStatus,
  UkResidency,
} from "../model/types";
import {
  hasAllUCATQualification,
  hasAnyUCATQualification,
  isUcatCourseCode,
  removeEmptyUCATFromQualifications,
  ucatQualificationsParser,
} from "../utils/ucatHelpers";
import { portalApplicant as validateApplicant } from "../model/validators";
import { applicant } from "../strings";
import {
  Box,
  Button,
  Checkbox,
  ConfirmObtainedGradesMessage,
  Field,
  FieldChildrenProps,
  FieldError,
  Flex,
  Form,
  Heading,
  Label,
  NoPredictedGradesMessage,
  NullableDateInput,
  NullableStringInput,
  Select,
  Text,
  ValidationMapping,
} from "../ui";
import { Opt } from "../utils/opt";
import { QualificationsEditor } from "./QualificationsEditor";
import { SelectValidProgramme } from "./SelectValidProgramme";
import update from "immutability-helper";
import { templateQualifications } from "@qmspringboard/shared/src/model/qualifications";
import { MessageError } from "./Messages";
import { unsafeStringToProgrammeCode } from "@qmspringboard/shared/src/model/programmeCode";
import currentYear from "@qmspringboard/shared/src/utils/currentYear";
import { SelectInterview } from "./Interview";
import { DateComponents } from "@qmspringboard/shared/src/ui/NullableDateInput";

export interface FormShape {
  qualifications: PortalQualifications;
  surname: Opt<string>;
  forenames: Opt<string>;
  dateOfBirth: DateComponents;
  telephone: Opt<string>;
  gdprAccept: boolean;
  hasBeenInterviewed: boolean | null;
  feeStatus: Opt<FeeStatus>;
  countryOfDomicile: Opt<CountryOfDomicile>;
  ukResidency: Opt<UkResidency>;
  ukImmigrationStatus: Opt<UkImmigrationStatus>;
  nationality: Opt<Nationality>;
}

const initialFormState: FormShape = {
  qualifications: {
    list: [],
    english: null,
    secondaryEducation: null,
  },
  surname: null,
  forenames: null,
  dateOfBirth: { day: null, month: null, year: null },
  telephone: null,
  gdprAccept: false,
  hasBeenInterviewed: null,
  feeStatus: null,
  ukResidency: null,
  ukImmigrationStatus: null,
  countryOfDomicile: null,
  nationality: null,
};

// ApplicantDTO is validated separately, this is just for local stuff that isn't sent to the server
const validateForm = z.object({
  dateOfBirth: DateComponents,
  forenames: z
    .string()
    .nullish()
    .refine(s => s, "Forename is required"),
  surname: z
    .string()
    .nullish()
    .refine(s => s, "Surname is required"),
  telephone: z
    .string()
    .nullish()
    .refine(s => s, "Contact Telephone is required"),
  gdprAccept: z.boolean().refine(value => value, "You must give us permission to store your information under our data governance policy"),
});
const validateProgrammeCode = z.string().min(1);

const formValidationMapping: ValidationMapping = {
  "details.telephone1": ["telephone"],
  "details.forenames": ["forenames"],
  "details.surname": ["surname"],
  "details.dateOfBirth": ["dateOfBirth"],
  "details.nationality": ["nationality"],
};

const mapToMessagesForm = (_applicant: ApplicantType) => {
  return (error: z.ZodError): Message[] => {
    const errors: Message[] = [];
    error.issues.forEach(stack => {
      const path = stack.path.join(".");
      errors.push({
        level: "error",
        text: stack.message,
        path: formValidationMapping[path] ? formValidationMapping[path] : toPath(stack.path),
      });
    });
    return errors;
  };
};

function createDateString(year: Opt<number>, month: Opt<number>, day: Opt<number>): string | null {
  return year && month && day
    ? `${(year.toString() || "0").padStart(4, "0")}-${(month.toString() || "0").padStart(2, "0")}-${(day.toString() || "0").padStart(2, "0")}`
    : null;
}

// only if we are new applicant
const mapFromFormToApplicantDTO = (applicant: ApplicantType, form: FormShape): ApplicantType => {
  return {
    ...applicant,
    qualifications: {
      ...form.qualifications,
      // @ts-expect-error till we fix types
      version: 1,
    },
    details: {
      ...applicant.details,
      dateOfBirth: createDateString(form.dateOfBirth.year, form.dateOfBirth.month, form.dateOfBirth.day),
      telephone1: form.telephone,
      surname: form.surname,
      forenames: form.forenames,
      ukResidency: form.ukResidency,
      ukImmigrationStatus: form.ukImmigrationStatus,
      countryOfDomicile: form.countryOfDomicile,
      nationality: form.nationality,
    },
  };
};

const mapToFormFromApplicantDTO =
  (applicant: ApplicantType) =>
  (form: FormShape): FormShape => {
    const { details, qualifications } = applicant;

    return {
      ...form,
      telephone: details.telephone1,
      surname: details.surname,
      forenames: details.forenames,
      qualifications,
    };
  };

const qualificationsNonEmpty = (quals: PortalQualifications) => {
  return quals.list.length > 0 && quals.secondaryEducation && quals.english !== undefined;
};

export interface IProps {
  applicantDTO: ApplicantType;
  submitting: boolean;
  onSubmit: (applicant: ApplicantType, programmeCode: string) => void;
  messages: Message[];
  defaultSchoolCode?: SchoolCode | null;
  defaultProgramme: ProgrammeCode | null;
}

/**
 * Present a four-stage form that gathers all information about a completely new Applicant that has no existing
 * record in Springboard.
 */
export default function Applicant({
  applicantDTO,
  onSubmit,
  submitting,
  defaultSchoolCode,
  defaultProgramme,
  messages: serverMessages,
  children,
}: React.PropsWithChildren<IProps>) {
  const initialState: FormShape = mapToFormFromApplicantDTO(applicantDTO)(initialFormState);

  const [programmeCode, _setProgrammeCode] = React.useState<ProgrammeCode | null>(defaultProgramme ?? null);

  const [messages, setMessages] = React.useState<Message[]>([]);

  const _onSubmit = React.useMemo(
    () => (values: FormShape) => {
      const newDTO = mapFromFormToApplicantDTO(applicantDTO, values);
      const validateAndSubmit = async () => {
        try {
          validateForm.parse(values);
          validateApplicant.parse(newDTO);
          validateProgrammeCode.parse(programmeCode);

          if (!programmeCode) {
            // for type checking meh.
            throw new Error("You must choose a programme code");
          }

          if (!newDTO.details.dateOfBirth) {
            // for type checking meh.
            throw new Error("You must enter a date of birth");
          }

          const oldEnoughToApply = isOldEnoughToApplyToCourse(new Date(newDTO.details.dateOfBirth), currentYear(), programmeCode);

          if (!oldEnoughToApply) {
            throw new MessageError({
              level: "error",
              path: ["dateOfBirth"],
              text: formatAgeConcernPortal(programmeCode),
            });
          }

          if (isUcatCourseCode(programmeCode)) {
            ucatQualificationsParser.parse(newDTO.qualifications.list);
          }

          onSubmit(newDTO, programmeCode);
          return;
        } catch (e) {
          if (e instanceof z.ZodError) {
            // console.log({ newDTO, e });
            setMessages(mapToMessagesForm(newDTO)(e));
            return false;
          } else if (e instanceof MessageError) {
            setMessages([e.data]);
            return false;
          } else if (typeof e === "string") {
            setMessages([
              {
                level: "error",
                path: [],
                text: e,
              },
            ]);
            return false;
          } else {
            throw e;
          }
        }
      };
      validateAndSubmit();
    },
    [applicantDTO, programmeCode, onSubmit, setMessages],
  );

  const countryOptions = useMemo(() => CountryOfDomicileEnum.dropdownOptionsWithBlank(false, "Choose"), []);

  const residencyOptions = useMemo(
    () =>
      UkResidencyEnum.dropdownOptionsWithBlank(false, "Choose").map(option => {
        return {
          ...option,
          label: appendResidencyLabelWithDateHint(option.value, option.label),
        };
      }),
    [],
  );

  const immigrationOptions = useMemo(() => UkImmigrationStatusEnum.dropdownOptionsWithBlank(false, "Choose"), []);

  const nationalityOptions = useMemo(() => NationalityEnum.dropdownOptionsWithBlank(false, "Choose"), []);

  return (
    <Form<FormShape> onSubmit={_onSubmit} initialState={initialState} messages={[...serverMessages, ...messages]}>
      {({ state, setState, unhandledMessages }) => {
        // FIXME: move this content into its own component

        const setProgrammeCode = (_code: string | null) => {
          const code = _code === null ? null : unsafeStringToProgrammeCode(_code);
          if (isUcatCourseCode(code)) {
            // add UCAT to our qualification list
            if (!hasAnyUCATQualification(state.qualifications.list)) {
              const extra = templateQualifications(QualificationBoardEnum.UCAT);
              const newState = update(state.qualifications, {
                list: {
                  $push: extra,
                },
              });
              setState("qualifications")(newState);
            }
          } else if (isUcatCourseCode(programmeCode)) {
            // if we *were* in a ucat programme, remove it from the qualification list.
            setState("qualifications")(
              update(state.qualifications, {
                list: {
                  $set: removeEmptyUCATFromQualifications(state.qualifications.list),
                },
              }),
            );
          }
          _setProgrammeCode(code);
        };

        const isUcat = isUcatCourseCode(programmeCode);

        return (
          <>
            <Heading variant="heading1" mb={2} id="grade-entry">
              <Text variant="ref" px={2} mr={2}>
                1
              </Text>
              {applicant.gradesHeading}
            </Heading>
            <NoPredictedGradesMessage />
            <Field name="qualifications" mb={2}>
              {() => (
                <Flex sx={{ flexDirection: "column" }}>
                  <QualificationsEditor programmeCode={programmeCode} value={state.qualifications} onChange={setState("qualifications")} />
                </Flex>
              )}
            </Field>
            {qualificationsNonEmpty(state.qualifications) && (
              <>
                <Heading variant="heading1">
                  <Text variant="ref" px={2} mr={2}>
                    2
                  </Text>
                  Your Nationality and Residency
                </Heading>
                <Box mb={2}>
                  <Field sx={{ flex: 1 }} name="nationality" label={applicant.nationality}>
                    {({ htmlProps }: FieldChildrenProps) => (
                      <Select {...htmlProps} options={nationalityOptions} value={state.nationality} onChange={setState("nationality")} />
                    )}
                  </Field>
                </Box>
                <Flex>
                  <Field sx={{ flex: 1 }} name="countryOfDomicile" label="Country of domicile">
                    {({ htmlProps }: FieldChildrenProps) => (
                      <Select
                        {...htmlProps}
                        options={countryOptions}
                        value={state.countryOfDomicile}
                        onChange={value => {
                          // Clear the programme selection
                          // in case the user has a partially closed programme selected
                          // and this change effectively closes it for them.
                          setProgrammeCode(null);
                          setState("countryOfDomicile")(value);
                        }}
                      />
                    )}
                  </Field>
                </Flex>
                <Box mb={2}>
                  <Field sx={{ flex: 1 }} name="ukResidency" label={applicant.residency}>
                    {({ htmlProps }: FieldChildrenProps) => (
                      <Select
                        {...htmlProps}
                        options={residencyOptions}
                        value={state.ukResidency}
                        onChange={value => {
                          // Clear the programme selection
                          // in case the user has a partially closed programme selected
                          // and this change effectively closes it for them.
                          setProgrammeCode(null);
                          setState("ukResidency")(value);
                        }}
                      />
                    )}
                  </Field>
                </Box>
                <Box mb={2}>
                  <Field sx={{ flex: 1 }} name="ukImmigrationStatus" label={applicant.immigration}>
                    {({ htmlProps }: FieldChildrenProps) => (
                      <Select
                        {...htmlProps}
                        options={immigrationOptions}
                        value={state.ukImmigrationStatus}
                        onChange={value => {
                          // Clear the programme selection
                          // in case the user has a partially closed programme selected
                          // and this change effectively closes it for them.
                          setProgrammeCode(null);
                          setState("ukImmigrationStatus")(value);
                        }}
                      />
                    )}
                  </Field>
                </Box>
              </>
            )}
            {qualificationsNonEmpty(state.qualifications) && state.ukResidency && state.ukImmigrationStatus && (
              <>
                <Heading variant="heading1" id="programme-selection">
                  <Text variant="ref" px={2} mr={2}>
                    3
                  </Text>
                  Your Programme
                </Heading>
                <SelectValidProgramme
                  qualifications={state.qualifications}
                  defaultSelectedSchoolCode={defaultSchoolCode || null}
                  programmeCode={programmeCode}
                  onChange={setProgrammeCode}
                  guessedFeeCategory={guessFeeCategory(state.feeStatus, state.ukResidency, state.ukImmigrationStatus)}
                />
                {isUcat ? (
                  <>
                    <Box bg="grey" mb={3} p={2}>
                      It looks like you are applying to a medical degree that requires a UCAT qualification. Ensure your UCAT score and band is
                      entered in <a href="#grade-entry">the qualifications section</a>
                    </Box>
                    <SelectInterview value={state.hasBeenInterviewed} onChange={setState("hasBeenInterviewed")} />
                  </>
                ) : null}
              </>
            )}
            {qualificationsNonEmpty(state.qualifications) && programmeCode ? (
              <>
                <Heading variant="heading1" id="personal-info">
                  About You
                </Heading>
                <Box bg="grey" mb={3} p={2}>
                  Enter your personal info below
                </Box>
                <Flex>
                  <Field name="forenames" sx={{ flex: 2 }} mr={2}>
                    {({ htmlProps }: FieldChildrenProps) => (
                      <NullableStringInput
                        {...htmlProps}
                        value={state.forenames}
                        onChange={setState("forenames")}
                        placeholder={applicant.placeholderForename}
                        mr={2}
                      />
                    )}
                  </Field>

                  <Field name="surname" sx={{ flex: 2 }}>
                    {({ htmlProps }: FieldChildrenProps) => (
                      <NullableStringInput
                        {...htmlProps}
                        value={state.surname}
                        onChange={setState("surname")}
                        placeholder={applicant.placeholderSurname}
                      />
                    )}
                  </Field>
                </Flex>
                <Flex>
                  <Field sx={{ flex: 1 }} name="telephone" label="Contact Telephone" mr={2}>
                    {({ htmlProps }: FieldChildrenProps) => (
                      <NullableStringInput
                        {...htmlProps}
                        value={state.telephone}
                        onChange={setState("telephone")}
                        placeholder={applicant.placeholderTelephone}
                      />
                    )}
                  </Field>
                  <Field sx={{ flex: 1 }} name="dateOfBirth" label="Date of Birth (for example 28 2 1985)">
                    {({ htmlProps }: FieldChildrenProps) => (
                      <NullableDateInput
                        {...htmlProps}
                        placeholder={["Day", "Month", "Year"]}
                        value={state.dateOfBirth}
                        onChange={setState("dateOfBirth")}
                      />
                    )}
                  </Field>
                </Flex>
                <Box mb={2}>
                  <Label>
                    <Checkbox checked={state.gdprAccept} onChange={setState("gdprAccept")} mr={2} />

                    {applicant.placeholderGDPR}
                  </Label>
                </Box>
                {unhandledMessages.length > 0
                  ? unhandledMessages.map((message, i) => (
                      <FieldError type={message.level} key={i}>
                        {message.text}
                      </FieldError>
                    ))
                  : null}
                <ConfirmObtainedGradesMessage />
                <Button
                  disabled={
                    submitting ||
                    (isUcat && (state.hasBeenInterviewed === null || state.hasBeenInterviewed === true)) ||
                    (isUcat && !hasAllUCATQualification(state.qualifications.list))
                  }
                  as={"input"}
                  variant="primary"
                  type="submit"
                  value={applicant.applicantButtonText}
                />
              </>
            ) : null}
            {children}
          </>
        );
      }}
    </Form>
  );
}
