import { emailAddress, emailAddressStrict, qualifications, ucasId, schemaForType } from "@qmspringboard/shared/dist/validators";
import * as z from "zod";
import { AuthenticationInformation } from "../api";
import { ApplicantDetails } from "./types";

export * from "@qmspringboard/shared/dist/validators";

export const login = schemaForType<Pick<AuthenticationInformation, "emailAddress" | "ucasId">>()(z.object({ emailAddress, ucasId }));

export const loginWarnings = z.object({ emailAddress: emailAddressStrict });

const valid = (message: string) => z.string({ invalid_type_error: message, required_error: message }).min(1, { message });

const details = schemaForType<Pick<ApplicantDetails, "telephone1" | "surname" | "forenames" | "dateOfBirth">>()(
  z.object(
    {
      telephone1: valid("Telephone is required"),
      surname: valid("Surname is required"),
      forenames: valid("Forename is required"),
      dateOfBirth: valid("Date of birth is required"),
      nationality: valid("Nationality is required"),
    },
    {
      description: "Details",
    },
  ),
);

export const portalApplicant = z.object({
  qualifications,
  details,
});
